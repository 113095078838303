var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Circle, Line } from "react-konva";
import { useSelector } from "react-redux";
import { setEraserTrash } from "../../../../../../../redux/actions/canvas/canvas";
export var CanvasCurve = function (_a) {
    var line = _a.line;
    var _b = useSelector(function (state) { return state; }).canvas, instrument = _b.instrument, isPressed = _b.isPressed, eraserTrashCan = _b.eraserTrashCan;
    // Добавляем элемент с список для удаления
    var setTrashedItem = function (id) {
        if (!eraserTrashCan.includes(id)) {
            setEraserTrash(__spreadArray(__spreadArray([], __read(eraserTrashCan), false), [id], false));
        }
    };
    return line.points.length > 2 ? (_jsx(Line, __assign({ opacity: eraserTrashCan.includes(line.id) ? 0.4 : 1, points: line.points, 
        // draggable={instrument === 'cursor'}
        // onDragStart={(e) => { e.currentTarget.moveToTop() }}
        // onClick={(e) => { e.currentTarget.moveToTop() }}
        onPointerOver: instrument === 'eraser' && isPressed ? function () { return setTrashedItem(line.id); } : undefined }, line.attr, { tension: 0.5, lineCap: "round", lineJoin: "round", globalCompositeOperation: 'source-over' }))) : (_jsx(Circle, { opacity: eraserTrashCan.includes(line.id) ? 0.4 : 1, x: line.points[0], y: line.points[1], onPointerOver: instrument === 'eraser' && isPressed ? function () { return setTrashedItem(line.id); } : undefined, fill: line.attr.stroke, radius: line.attr.strokeWidth * 0.7 }));
};
