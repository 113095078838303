import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import '../../../../../../../../assets/styles/scss/componets/pages/home/header/components/context-menu.scss';
import { PencilContext } from './components/pencil/PencilContext';
import { HiddenContextToggle } from './components/HiddenContextToggle';
import { MicrophoneContext } from './components/microphone/MicrophoneContext';
import { CameraContext } from './components/camera/CameraContext';
import { LineContext } from './components/line/LIneContext';
import { GalleryContext } from './components/gallery/GalleryContext';
import { NoteContext } from './components/note/NoteContext';
export var ContextMenu = function () {
    return (_jsxs(_Fragment, { children: [_jsx(HiddenContextToggle, {}), _jsx(PencilContext, {}), _jsx(LineContext, {}), _jsx(NoteContext, {}), _jsx(CameraContext, {}), _jsx(MicrophoneContext, {}), _jsx(GalleryContext, {})] }));
};
