// Преобразуем картинку в строку формата base64
export var imageToJSON = function (image) {
    console.log(image);
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;
    ctx === null || ctx === void 0 ? void 0 : ctx.drawImage(image, 0, 0);
    var base64Image = canvas.toDataURL();
    var imageJSON = {
        data: base64Image,
        width: image.width,
        height: image.height
    };
    return imageJSON;
};
export var base64ToImage = function (base64) {
    return new Promise((function (resolve) {
        var imageObj = new Image();
        imageObj.src = base64;
        imageObj.onload = function () { resolve(imageObj); };
    }));
};
// const getBase64FromUrl = async (url: any) => {
//   const data = await fetch(url)
//   const blob = await data.blob()
//   return new Promise((resolve) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(blob)
//     reader.onloadend = () => {
//       const base64data = reader.result
//       resolve(base64data)
//     }
//   });
// }
