var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
export var Header = function () {
    return (_jsx(_Fragment, { children: _jsxs("header", __assign({ style: { height: '1px', width: '1px' }, className: "overflow-hidden absolute" }, { children: [_jsx("h1", { children: "Coloring bookmark red" }), _jsx("h3", { children: "This web app use for the drawing and teach the math" })] })) }));
};
