var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { returnPrevious } from "../../../../../../../redux/actions/canvas/canvas";
import { images } from "../../../../../../../assets/images/images";
export var Changes = function () {
    var _a = useSelector(function (state) { return state.canvas; }), activeSceneId = _a.activeSceneId, scenes = _a.scenes, isPressed = _a.isPressed;
    var scene = scenes.find(function (scene) { return scene.canvasID === activeSceneId; });
    var returnPreviousChange = function () {
        if (scene && scene.history.length) {
            returnPrevious();
        }
    };
    return (_jsx("article", __assign({ className: clsx("app-changes-area mr-5 flex align-items-center justify-content-center relative", { 'pointer-events-none': isPressed }) }, { children: _jsx(Button, __assign({ onClick: function () { return returnPreviousChange(); }, className: clsx('w-3rem return-previous-change remove-button-text', {
                'disable': scene && !scene.history.length
            }) }, { children: _jsx("img", { src: images.returnImage, alt: "returnImage" }) })) })));
};
