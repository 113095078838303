import cursor from './components/particals/header/instruments/cursor.png';
import line from './components/particals/header/instruments/line.png';
import load from './components/particals/header/menu/load.png';
import folder from './components/particals/header/menu/folder.png';
import exportPDF from './components/particals/header/menu/exportPDF.png';
import exportPNG from './components/particals/header/menu/exportPNG.png';
import importPDF from './components/particals/header/menu/importPDF.png';
import removePDF from './components/particals/header/menu/removePDF.png';
import clean from './components/particals/header/menu/clean.png';
import trashCan from './components/particals/header/menu/trashCan.png';
import curve1 from './components/particals/header/context-menu/curve1.png';
import curve2 from './components/particals/header/context-menu/curve2.png';
import curve3 from './components/particals/header/context-menu/curve3.png';
import dashedCurve from './components/particals/header/context-menu/dashedCurve.png';
import returnImage from './components/particals/footer/return.png';
import defaultImport from './components/home/defaultImport.png';
import specialImport from './components/home/specialImport.png';
export var images = {
    cursor: cursor,
    line: line,
    load: load,
    folder: folder,
    removePDF: removePDF,
    importPDF: importPDF,
    exportPDF: exportPDF,
    exportPNG: exportPNG,
    returnImage: returnImage,
    specialImport: specialImport,
    defaultImport: defaultImport,
    trashCan: trashCan,
    clean: clean,
    curve1: curve1,
    curve2: curve2,
    curve3: curve3,
    dashedCurve: dashedCurve,
};
