var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { scaleDecrease, scaleIncrease, setDefaultScale } from "../../../../../../../redux/actions/canvas/canvas";
export var Scale = function () {
    var _a = useSelector(function (state) { return state.canvas; }), scale = _a.scale, isPressed = _a.isPressed;
    return (_jsxs("article", __assign({ className: clsx("app-scale-area ml-5 flex align-items-center justify-content-center", { 'pointer-events-none': isPressed }) }, { children: [_jsx(Button, { onClick: scaleDecrease, className: "pi pi-minus remove-button-text" }), _jsxs(Button, __assign({ onClick: setDefaultScale, className: "w-4rem default-scale flex align-items-center justify-content-center" }, { children: [(scale * 100).toFixed(0), "%"] })), _jsx(Button, { onClick: scaleIncrease, className: "pi pi-plus remove-button-text" })] })));
};
