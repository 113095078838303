var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { addNewScene, changePage } from "../../../../../../../redux/actions/canvas/canvas";
import { useEffect, useState } from "react";
import { clone } from "../../../../../../../utils/clone";
var generateIds = function (n) { return Array.from({ length: n }, function (_, i) { return i; }); };
export var Pages = function () {
    var _a = useSelector(function (state) { return state.canvas; }), activeSceneId = _a.activeSceneId, scenes = _a.scenes, isPressed = _a.isPressed;
    var _b = __read(useState([]), 2), ids = _b[0], setIds = _b[1];
    var _c = __read(useState(generateIds(scenes.length)), 2), displayedIds = _c[0], setDisplayedIds = _c[1];
    var prev = function () {
        var first = displayedIds[0];
        var id = ids[first - 1];
        if (id !== undefined) {
            var arr = clone(displayedIds);
            arr.pop();
            arr.unshift(id);
            setDisplayedIds(arr);
        }
    };
    var next = function () {
        var last = displayedIds[displayedIds.length - 1];
        var id = ids[last + 1];
        if (id !== undefined) {
            var arr = clone(displayedIds);
            arr.shift();
            arr.push(id);
            setDisplayedIds(arr);
        }
    };
    var createPage = function () {
        var id = addNewScene();
        setDisplayedIds([id, id - 1, id - 2].sort());
    };
    useEffect(function () {
        setIds(generateIds(scenes.length));
        var reloadIds = function () {
            if (activeSceneId === scenes[0].canvasID) {
                return setDisplayedIds([activeSceneId, activeSceneId + 1, activeSceneId + 2]);
            }
            if (activeSceneId === scenes[scenes.length - 1].canvasID) {
                return setDisplayedIds([activeSceneId, activeSceneId - 1, activeSceneId - 2].sort());
            }
            setDisplayedIds([activeSceneId - 1, activeSceneId, activeSceneId + 1]);
        };
        reloadIds();
    }, [scenes.length]);
    return (_jsxs("div", __assign({ className: "mr-5 flex align-items-center" }, { children: [_jsxs("article", __assign({ className: clsx("app-pages-area flex align-items-center justify-content-center", { 'pointer-events-none': isPressed }) }, { children: [scenes.length > 3 && (_jsx(Button, { disabled: displayedIds[0] === 0, onClick: prev, className: clsx('page-changer pi pi-angle-left w-3rem remove-button-text page-counter', { 'disable': displayedIds[0] === 0 }) })), displayedIds.map(function (id) {
                        if (scenes[id]) {
                            return (_jsx(Button, __assign({ label: (scenes[id].canvasID + 1).toString(), onClick: function () { return changePage(id); }, className: clsx('page-changer w-3rem remove-button-text relative', {
                                    'active': activeSceneId === id
                                }) }, { children: _jsx("i", { className: clsx("pi pi-microphone absolute", { 'hidden': scenes[id].voiceRecords.length === 0 }) }) }), id));
                        }
                        else {
                            return '';
                        }
                    }), scenes.length > 3 && (_jsx(Button, { onClick: next, disabled: displayedIds[displayedIds.length - 1] === scenes[scenes.length - 1].canvasID, className: clsx('page-changer pi pi-angle-right w-3rem remove-button-text page-counter', { 'disable': displayedIds[displayedIds.length - 1] === scenes[scenes.length - 1].canvasID }) }))] })), _jsx("article", __assign({ className: clsx("custom-app-pages-area flex align-items-center justify-content-center", { 'pointer-events-none': isPressed }) }, { children: _jsx(Button, { onClick: createPage, className: clsx('page-changer pi pi-plus w-3rem remove-button-text add-page') }) }))] })));
};
