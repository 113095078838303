import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { setInstrument } from "../../../../../../../redux/actions/canvas/canvas";
import { isSafari } from "../../../../../../../utils/browsers";
export var Camera = function () {
    var _a = useSelector(function (state) { return state.canvas; }), instrument = _a.instrument, isCameraActive = _a.isCameraActive, isCameraPaused = _a.isCameraPaused;
    var instrumentToggle = function () { return setInstrument('camera'); };
    return (_jsx(Button, { onClick: instrumentToggle, className: clsx({
            "pi pi-video remove-button-text camera-instrument": true,
            "active": instrument === 'camera',
            "isRecord": isCameraActive,
            "isPaused": isCameraPaused,
            'hidden': isSafari(),
        }) }));
};
