var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useInterval } from "react-use";
import { useEffect, useRef, useState } from "react";
import { format, fromUnixTime } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { disableMicrophone, enableMicrophone, getCurrentScene, setUnhiddenContextMenuMode, updateScene } from "../../../../../../../../../../redux/actions/canvas/canvas";
export var MicrophoneContext = function () {
    var _a = useSelector(function (state) { return state.canvas; }), instrument = _a.instrument, hiddenContextMenuMode = _a.hiddenContextMenuMode, activeSceneId = _a.activeSceneId, scenes = _a.scenes;
    var voiceRecordsLimit = 8;
    var _b = __read(useState(false), 2), recording = _b[0], setRecording = _b[1];
    var _c = __read(useState(0), 2), elapsedTime = _c[0], setElapsedTime = _c[1];
    var _d = __read(useState(null), 2), playing = _d[0], setPlaying = _d[1];
    var mediaRecorder = useRef(null);
    var streamRef = useRef(null);
    var startTimeRef = useRef(null);
    var _e = __read(useState({ voiceRecords: [] }), 2), currentScene = _e[0], setCurrentScene = _e[1];
    var audioRef = useRef(null);
    useEffect(function () {
        setElapsedTime(0);
    }, [recording]);
    useEffect(function () {
        var scene = getCurrentScene();
        setCurrentScene(scene);
    }, [scenes[activeSceneId].voiceRecords.length]);
    useEffect(function () {
        if (audioRef.current) {
            audioRef.current.onended = onEnded;
            return function () {
                if (audioRef.current)
                    audioRef.current.onended = null;
            };
        }
    }, [audioRef]);
    var addVoiceRecord = function (record) {
        var scene = getCurrentScene();
        if (scene) {
            scene.voiceRecords = __spreadArray(__spreadArray([], __read(scene.voiceRecords), false), [record], false);
            updateScene(scene);
        }
    };
    var removeVoiceRecord = function (id) {
        var scene = getCurrentScene();
        if (scene) {
            scene.voiceRecords = scene.voiceRecords.filter(function (record) { return record.id !== id; });
            updateScene(scene);
        }
    };
    useInterval(function () {
        if (recording) {
            setElapsedTime(function (prev) { return prev + 1000; });
        }
        else if (playing) {
            setPlaying(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), { currentTime: ((_a = audioRef.current) === null || _a === void 0 ? void 0 : _a.currentTime) ? audioRef.current.currentTime * 1000 : 0 }));
            });
        }
    }, 1000);
    function startRecording() {
        if (currentScene.voiceRecords.length > voiceRecordsLimit)
            return;
        try {
            // Stop any currently playing audio
            stopPlaying();
            var start_1 = Date.now(); // Store the start time of the recording
            startTimeRef.current = start_1;
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(function (stream) {
                enableMicrophone();
                streamRef.current = stream;
                mediaRecorder.current = new MediaRecorder(stream);
                mediaRecorder.current.start();
                var data = [];
                mediaRecorder.current.addEventListener('dataavailable', function (event) {
                    data.push(event.data);
                });
                mediaRecorder.current.addEventListener('stop', function () {
                    var audioBlob = new Blob(data);
                    var url = URL.createObjectURL(audioBlob);
                    var duration = Date.now() - start_1; // Calculate the duration
                    var lastRecord = currentScene.voiceRecords[currentScene.voiceRecords.length - 1];
                    var idCounter = lastRecord ? lastRecord.idCounter + 1 : 1;
                    addVoiceRecord({
                        id: uuidv4(),
                        url: url,
                        idCounter: idCounter,
                        date: new Date(),
                        duration: duration,
                        x: 25,
                        y: 30
                    });
                });
                setRecording(true);
                // Stop recording after 1 minutes
                setTimeout(function () {
                    finishRecording();
                }, 60000); // 60000 ms = 1 minutes
            })
                .catch(function (error) {
                console.error("Error accessing microphone:", error);
            });
        }
        catch (error) {
            console.error("Error accessing microphone:", error);
        }
    }
    function finishRecording() {
        if (mediaRecorder.current) {
            mediaRecorder.current.stop();
            if (streamRef.current) {
                streamRef.current.getTracks()[0].stop();
            }
            mediaRecorder.current = null; // Reset the media recorder reference
            streamRef.current = null; // Reset the stream reference
        }
        setRecording(false);
        disableMicrophone();
    }
    function playRecording(recording) {
        if (audioRef.current) {
            audioRef.current.src = recording.url;
            audioRef.current.play().catch(function (error) { return console.error("Playback failed: ", error); });
            setPlaying(__assign(__assign({}, recording), { currentTime: 0 }));
        }
    }
    function stopPlaying() {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setPlaying(null);
        }
    }
    function onEnded() {
        setPlaying(null);
    }
    useEffect(function () {
        if (audioRef.current) {
            audioRef.current.onended = onEnded;
        }
    }, [audioRef]);
    return (_jsxs("div", __assign({ className: clsx("context-canvas-menu", {
            'hidden': instrument !== 'microphone' || !hiddenContextMenuMode
        }) }, { children: [_jsxs("div", __assign({ className: "microphone-area" }, { children: [_jsxs("div", __assign({ className: "flex align-items-center justify-content-between gap-2" }, { children: [_jsx("i", { onClick: startRecording, className: clsx("pi pi-microphone control-micro-btn", {
                                    'hidden': recording,
                                    'disable-mode': currentScene.voiceRecords.length > voiceRecordsLimit
                                }) }), _jsx("i", { onClick: finishRecording, className: clsx("pi pi-stop-circle control-micro-btn", {
                                    'hidden': !recording
                                }) }), _jsx("span", __assign({ className: "time-duration" }, { children: format(fromUnixTime(elapsedTime / 1000), 'mm:ss') }))] })), _jsx("ul", __assign({ className: clsx("records", {
                            'max': currentScene.voiceRecords.length > 4,
                            'm-0': currentScene.voiceRecords.length === 0
                        }) }, { children: currentScene.voiceRecords.map(function (recording, index) { return (_jsxs("li", __assign({ className: "record" }, { children: [_jsxs("div", __assign({ className: "flex align-items-center gap-1" }, { children: [_jsx("span", __assign({ className: "counter" }, { children: recording.idCounter })), playing && playing.url === recording.url ? (_jsx("i", { onClick: stopPlaying, className: clsx("pi pi-stop-circle control-record-btn") })) : (_jsxs("div", __assign({ className: "flex align-items-center" }, { children: [_jsx("i", { onClick: function () { return playRecording(recording); }, className: clsx("pi pi-caret-right control-record-btn") }), _jsx("i", { onClick: function () { return removeVoiceRecord(recording.id); }, className: clsx("pi pi-trash control-record-btn delete-control-record-btn") })] })))] })), playing && playing.url === recording.url ? (_jsx("span", __assign({ className: "duration" }, { children: format(fromUnixTime(playing.currentTime / 1000), 'mm:ss') }))) : (_jsx("span", __assign({ className: "duration" }, { children: format(fromUnixTime(recording.duration / 1000), 'mm:ss') })))] }), index)); }) })), _jsx("audio", { ref: audioRef })] })), _jsxs("button", __assign({ onClick: function () { return setUnhiddenContextMenuMode(); }, className: "hide-context-button" }, { children: [_jsx("i", { className: "pi pi-chevron-left" }), _jsx("span", { children: "Hide settings" })] }))] })));
};
