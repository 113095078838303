import { REMOVE_NOTIFICATION, SET_NOTIFICATION } from '../../reducers/notifications';
import store from '../../store';
var notificationLife = 3000;
export var setNotification = function (params) {
    var notification = {
        type: SET_NOTIFICATION,
        payload: params
    };
    // Чистим предыдущие уведомления
    removeNotification();
    return store.dispatch(notification);
};
export var removeNotification = function () {
    var notification = {
        type: REMOVE_NOTIFICATION
    };
    return store.dispatch(notification);
};
export var successNotification = function (message) {
    var params = {
        severity: 'success',
        summary: 'Success',
        detail: message,
        life: notificationLife
    };
    setNotification(params);
};
export var errorNotification = function (message) {
    var params = {
        severity: 'error',
        summary: 'Error',
        detail: message,
        life: notificationLife
    };
    setNotification(params);
};
export var infoNotification = function (message) {
    var params = {
        severity: 'info',
        summary: 'Info',
        detail: message,
        life: notificationLife
    };
    setNotification(params);
};
