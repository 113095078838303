var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Text } from "react-konva";
import { useSelector } from "react-redux";
import { Html } from "react-konva-utils";
import { addHistory, cancelSelectedItem, findNoteById, replaceNoteById, resaveNote, setEditableItem, setEraserTrash, setInstrument, setSelectedItem } from "../../../../../../../redux/actions/canvas/canvas";
import Konva from "konva";
export var getDimensionsByNote = function (note) {
    var simpleText = new Konva.Text({
        x: 10,
        y: 15,
        text: note.content,
        fontSize: note.attr.fontSize,
        fontFamily: note.attr.fontFamily,
        fill: 'green'
    });
    var lines = note.content.split("\n");
    var numberOfLines = lines.length;
    var coof = note.attr.fontSize * 2;
    return {
        width: simpleText.width() + coof,
        height: simpleText.height() + coof
    };
};
export var CanvasNote = function (_a) {
    var note = _a.note;
    var _b = useSelector(function (state) { return state; }).canvas, instrument = _b.instrument, selectedItem = _b.selectedItem, editableItem = _b.editableItem, eraserTrashCan = _b.eraserTrashCan, isPressed = _b.isPressed, currentStage = _b.currentStage;
    var onItemTransformEnd = function (event) {
        addHistory();
        var x = event.target.x();
        var y = event.target.y();
        var rotation = event.target.rotation();
        var height = event.target.height();
        var width = event.target.width();
        resaveNote(note.id, x, y, rotation, height, width);
    };
    var onItemDragEnd = function (event) {
        addHistory();
        var x = event.target.x();
        var y = event.target.y();
        var rotation = event.target.rotation();
        var height = event.target.height();
        var width = event.target.width();
        resaveNote(note.id, x, y, rotation, height, width);
        cancelSelectedItem();
    };
    var onItemClick = function (event, selectedItemId, itemId) {
        // if (!['cursor', 'note'].includes(instrument)) return;
        if (!['cursor'].includes(instrument))
            return;
        event.currentTarget.moveToTop();
        if (itemId === selectedItemId)
            return cancelSelectedItem();
        setSelectedItem({ type: 'note', id: itemId, ref: event.target });
    };
    // Добавляем элемент с список для удаления
    var setTrashedItem = function (id) {
        if (!eraserTrashCan.includes(id)) {
            setEraserTrash(__spreadArray(__spreadArray([], __read(eraserTrashCan), false), [id], false));
        }
    };
    var handleDblClick = function (note) {
        cancelSelectedItem();
        setEditableItem(note);
        setInstrument('note');
    };
    var onChangeNoteContent = function (note, content) {
        var _a = getDimensionsByNote(note), width = _a.width, height = _a.height;
        content = content.replaceAll('\n', " \n");
        var noteElement = findNoteById(note.id);
        noteElement.width = width;
        noteElement.height = height;
        noteElement.content = content;
        replaceNoteById(note.id, noteElement);
    };
    var otherParams = {};
    if (note.height && note.width) {
        otherParams.height = note.height;
        otherParams.width = note.width;
    }
    return (editableItem && (editableItem.id === note.id) ? (_jsx(Html, __assign({ groupProps: {
            x: note.points[0],
            y: note.points[1],
            rotation: note.rotation,
        }, divProps: { style: { opacity: 1 } } }, { children: _jsx("textarea", { autoFocus: true, onChange: function (event) { return onChangeNoteContent(note, event.target.value); }, style: {
                width: "".concat(note.width, "px"),
                height: "".concat(note.height, "px"),
                fontSize: "".concat(note.attr.fontSize, "px"),
                fontFamily: note.attr.fontFamily,
                color: note.attr.color
            }, className: "editable-note-textarea", defaultValue: note.content }) }))) : (_jsx(Text, { opacity: eraserTrashCan.includes(note.id) ? 0.4 : 1, x: note.points[0], y: note.points[1], text: note.content, width: note.width, height: note.height, onPointerOver: instrument === 'eraser' && isPressed ? function () { return setTrashedItem(note.id); } : undefined, rotation: note.rotation, draggable: ['cursor'].includes(instrument), 
        // draggable={['cursor', 'note'].includes(instrument)}
        fill: note.attr.color, onPointerClick: function (event) { return onItemClick(event, selectedItem ? selectedItem.id : '', note.id); }, fontSize: note.attr.fontSize, fontFamily: note.attr.fontFamily, onPointerDblClick: function () { return handleDblClick(note); }, onDragEnd: function (event) { return onItemDragEnd(event); }, onTransformEnd: function (event) { return onItemTransformEnd(event); } })));
};
