var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useState } from "react";
import { images } from "../../../../../../../../../../../assets/images/images";
import { changePage, hideCanvasMenu, resaveCanvasStore, setExportMode } from "../../../../../../../../../../../redux/actions/canvas/canvas";
import store from "../../../../../../../../../../../redux/store";
import { clone } from "../../../../../../../../../../../utils/clone";
import clsx from 'clsx';
export var LoadProjectButton = function () {
    var _a = __read(useState(false), 2), modalVisible = _a[0], setModalVisible = _a[1];
    var _b = __read(useState(0), 2), modalProgress = _b[0], setModalProgress = _b[1];
    var base64ToImage = function (base64) {
        return new Promise((function (resolve) {
            var imageObj = new Image();
            imageObj.src = base64;
            imageObj.onload = function () { resolve(imageObj); };
        }));
    };
    var loadProject = function () {
        var input = document.createElement('input');
        input.type = 'file';
        input.accept = '.coloring, .json';
        input.onchange = function (event) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, file, data, scenes, canvas, clonesCanvas, _loop_1, scenes_1, scenes_1_1, scene, e_1_1, delay_1, intervalDuration, counter_1, intervalId_1;
            var e_1, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = __read(event.target.files, 1), file = _a[0];
                        if (!file) return [3 /*break*/, 13];
                        setModalVisible(true);
                        return [4 /*yield*/, file.text()];
                    case 1:
                        data = _c.sent();
                        scenes = JSON.parse(data);
                        canvas = store.getState().canvas;
                        clonesCanvas = clone(canvas);
                        clonesCanvas.activeSceneId = 0;
                        _loop_1 = function (scene) {
                            var images_2, preparedImages, images_1, images_1_1, image, preparedImg, e_2_1, backgroundImage, preparedAudio;
                            var e_2, _d;
                            return __generator(this, function (_e) {
                                switch (_e.label) {
                                    case 0:
                                        images_2 = scene.images;
                                        preparedImages = [];
                                        _e.label = 1;
                                    case 1:
                                        _e.trys.push([1, 6, 7, 8]);
                                        images_1 = (e_2 = void 0, __values(images_2)), images_1_1 = images_1.next();
                                        _e.label = 2;
                                    case 2:
                                        if (!!images_1_1.done) return [3 /*break*/, 5];
                                        image = images_1_1.value;
                                        return [4 /*yield*/, base64ToImage(image.image)];
                                    case 3:
                                        preparedImg = _e.sent();
                                        preparedImages.push(__assign(__assign({}, image), { image: preparedImg }));
                                        _e.label = 4;
                                    case 4:
                                        images_1_1 = images_1.next();
                                        return [3 /*break*/, 2];
                                    case 5: return [3 /*break*/, 8];
                                    case 6:
                                        e_2_1 = _e.sent();
                                        e_2 = { error: e_2_1 };
                                        return [3 /*break*/, 8];
                                    case 7:
                                        try {
                                            if (images_1_1 && !images_1_1.done && (_d = images_1.return)) _d.call(images_1);
                                        }
                                        finally { if (e_2) throw e_2.error; }
                                        return [7 /*endfinally*/];
                                    case 8:
                                        scene.images = preparedImages;
                                        if (!scene.background) return [3 /*break*/, 10];
                                        return [4 /*yield*/, base64ToImage(scene.background.image)];
                                    case 9:
                                        backgroundImage = _e.sent();
                                        scene.background = __assign(__assign({}, scene.background), { image: backgroundImage });
                                        _e.label = 10;
                                    case 10:
                                        preparedAudio = [];
                                        scene.voiceRecords.map(function (recording) {
                                            // Convert base64 to Blob
                                            if (recording.url.split(',')[1]) {
                                                var byteString = atob(recording.url.split(',')[1]);
                                                var mimeString = recording.url.split(',')[0].split(':')[1].split(';')[0];
                                                var arrayBuffer = new ArrayBuffer(byteString.length);
                                                var uintArray = new Uint8Array(arrayBuffer);
                                                for (var i = 0; i < byteString.length; i++) {
                                                    uintArray[i] = byteString.charCodeAt(i);
                                                }
                                                var blob = new Blob([uintArray], { type: mimeString });
                                                // Convert Blob to URL
                                                var url = URL.createObjectURL(blob);
                                                preparedAudio.push(__assign(__assign({}, recording), { url: url }));
                                            }
                                        });
                                        scene.voiceRecords = preparedAudio;
                                        // Обратная совместимость
                                        if (!scene.lines)
                                            scene.lines = [];
                                        return [2 /*return*/];
                                }
                            });
                        };
                        _c.label = 2;
                    case 2:
                        _c.trys.push([2, 7, 8, 9]);
                        scenes_1 = __values(scenes), scenes_1_1 = scenes_1.next();
                        _c.label = 3;
                    case 3:
                        if (!!scenes_1_1.done) return [3 /*break*/, 6];
                        scene = scenes_1_1.value;
                        return [5 /*yield**/, _loop_1(scene)];
                    case 4:
                        _c.sent();
                        _c.label = 5;
                    case 5:
                        scenes_1_1 = scenes_1.next();
                        return [3 /*break*/, 3];
                    case 6: return [3 /*break*/, 9];
                    case 7:
                        e_1_1 = _c.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 9];
                    case 8:
                        try {
                            if (scenes_1_1 && !scenes_1_1.done && (_b = scenes_1.return)) _b.call(scenes_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 9:
                        clonesCanvas.scenes = scenes;
                        resaveCanvasStore(clonesCanvas);
                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 100); })];
                    case 10:
                        _c.sent();
                        changePage(1);
                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 100); })];
                    case 11:
                        _c.sent();
                        changePage(0);
                        delay_1 = 2000;
                        intervalDuration = delay_1 / 100;
                        counter_1 = 0;
                        intervalId_1 = setInterval(function () {
                            if (counter_1 >= 100) {
                                clearInterval(intervalId_1);
                                return;
                            }
                            // Ваш код для выполнения в каждом интервале
                            setModalProgress(counter_1);
                            counter_1++;
                        }, intervalDuration);
                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, delay_1); })
                            // Убираем модалу
                        ];
                    case 12:
                        _c.sent();
                        // Убираем модалу
                        setModalVisible(false);
                        setExportMode(false);
                        setModalProgress(0);
                        hideCanvasMenu();
                        _c.label = 13;
                    case 13: return [2 /*return*/];
                }
            });
        }); };
        input.click();
    };
    var accept = function () {
        setExportMode(true);
        loadProject();
    };
    var onLoadProject = function () {
        confirmDialog({
            message: _jsx(_Fragment, { children: _jsx("p", { children: "Previous data will be deleted." }) }),
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: accept,
            reject: function () { }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs("li", __assign({ onClick: onLoadProject }, { children: [_jsx("img", { src: images.folder, alt: "canvas-menu-img" }), _jsx("span", { children: "Load project" })] })), _jsx(ConfirmDialog, {}), _jsxs("div", __assign({ className: clsx('pdf-loader-modal z-3', {
                    'hidden': !modalVisible
                }) }, { children: [_jsx("span", { children: "Project loading.." }), _jsxs("h3", { children: [modalProgress.toFixed(0), "%"] })] }))] }));
};
