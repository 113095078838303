var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { images } from "../../../../../../../../../../assets/images/images";
import { disableLineDashedMode, enableLineDashedMode, setLineColor, setLineStrokeWidth, setUnhiddenContextMenuMode } from "../../../../../../../../../../redux/actions/canvas/canvas";
export var LineContext = function () {
    var _a = useSelector(function (state) { return state.canvas; }), instrument = _a.instrument, lineAttrs = _a.lineAttrs, hiddenContextMenuMode = _a.hiddenContextMenuMode;
    var colors = [
        '#AD715E',
        '#D06C64',
        '#F93A23',
        '#FB573C',
        '#E04EB0',
        '#FFAC47',
        '#43D693',
        '#17A765',
        '#4987E7',
        '#B99BFF',
        '#000000',
        '#ffffff',
    ];
    var dashedModeToggle = function () {
        if (lineAttrs.dash)
            disableLineDashedMode();
        else
            enableLineDashedMode();
    };
    return (_jsxs("div", __assign({ className: clsx("context-canvas-menu", {
            'hidden': instrument !== 'line' || !hiddenContextMenuMode
        }) }, { children: [_jsxs("div", __assign({ className: 'context-block' }, { children: [_jsx("label", { children: "Size" }), _jsxs("div", __assign({ className: 'elements flex flex-wrap' }, { children: [_jsx("div", __assign({ onClick: function () { return setLineStrokeWidth(4); }, className: clsx('size element', { 'active': lineAttrs.strokeWidth === 4 }) }, { children: _jsx("img", { src: images.curve1, alt: "element-img" }) })), _jsx("div", __assign({ onClick: function () { return setLineStrokeWidth(10); }, className: clsx('size element', { 'active': lineAttrs.strokeWidth === 10 }) }, { children: _jsx("img", { src: images.curve2, alt: "element-img" }) })), _jsx("div", __assign({ onClick: function () { return setLineStrokeWidth(18); }, className: clsx('size element', { 'active': lineAttrs.strokeWidth === 18 }) }, { children: _jsx("img", { src: images.curve3, alt: "element-img" }) })), _jsx("div", __assign({ className: clsx('size element', { 'active': lineAttrs.dash }), onClick: dashedModeToggle }, { children: _jsx("img", { src: images.dashedCurve, alt: "element-img" }) }))] }))] })), _jsxs("div", __assign({ className: 'context-block' }, { children: [_jsx("label", { children: "Color" }), _jsx("div", __assign({ className: 'elements flex flex-wrap' }, { children: colors.map(function (color, index) {
                            return (_jsx("div", { style: {
                                    background: color
                                }, onClick: function () { return setLineColor(color); }, className: clsx('element color', {
                                    'white': color === '#ffffff',
                                    'active': lineAttrs.stroke === color
                                }) }, color + index));
                        }) }))] })), _jsxs("button", __assign({ onClick: function () { return setUnhiddenContextMenuMode(); }, className: "hide-context-button" }, { children: [_jsx("i", { className: "pi pi-chevron-left" }), _jsx("span", { children: "Hide settings" })] }))] })));
};
