import { addHistory, getCurrentScene, setEraserTrash, setPressingMode, updateScene } from "../../../redux/actions/canvas/canvas";
import store from "../../../redux/store";
export var start = function (event) {
    setPressingMode(true);
};
export var move = function (event) { };
export var finish = function (event) {
    var eraserTrashCan = store.getState().canvas.eraserTrashCan;
    setPressingMode(false);
    if (eraserTrashCan.length > 0)
        addHistory();
    // Удаляем элементы
    var scene = getCurrentScene();
    if (scene) {
        scene.curves = scene.curves.filter(function (item) { return !eraserTrashCan.includes(item.id); });
        scene.images = scene.images.filter(function (item) { return !eraserTrashCan.includes(item.id); });
        scene.lines = scene.lines.filter(function (item) { return !eraserTrashCan.includes(item.id); });
        scene.notes = scene.notes.filter(function (item) { return !eraserTrashCan.includes(item.id); });
        updateScene(scene);
        setTimeout(function () { setEraserTrash([]); }, 0);
    }
};
