import axios from "axios";
import { localeOption } from "primereact/api";
import Parser from 'html-react-parser';
import { errorNotification } from "../redux/actions/notifications/notifications";
import store from "../redux/store";
export var initInterceptor = function () {
    var reduxStore = store.getState();
    var lang = reduxStore.languages.lang;
    axios.interceptors.response.use(function (response) { return response; }, function (error) {
        var _a, _b, _c;
        var status = (_a = error.response) === null || _a === void 0 ? void 0 : _a.status;
        console.log(error);
        var message = (_c = (_b = error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message;
        if (error && error.code === 'ERR_CANCELED')
            return false;
        // Access Denien
        // if (status && [403].includes(status)) {
        //   errorNotification(message || Parser(localeOption("interceptorErrorNotification")))
        //   return setTimeout(() => window.location.replace("/"), 1000)
        // }
        // Log out by invalid session reason 
        // if (status && [401].includes(status)) logout()
        // Page not found
        if (status && [429].includes(status))
            return errorNotification(message);
        var defaultMessage = Parser(localeOption("Interceptor Error Notification", lang));
        // Показываем ошибку
        errorNotification(message || defaultMessage);
        return Promise.reject(error);
    });
};
