import axios from "axios";
var path = function (path) { return process.env.SERVER_API + path; };
export var generateImages = function (data, cancelToken) {
    var _a;
    var config = {
        method: 'post',
        url: path('/generate-images'),
        data: data,
        // Отмена запроса
        cancelToken: (_a = cancelToken.current) === null || _a === void 0 ? void 0 : _a.token
    };
    return axios(config);
};
