var initialState = {
    isAuthenticated: true,
    user: {
        name: 'Guest'
    }
};
export var SET_CURRENT_USER = 'SET_CURRENT_USER';
export var authReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        default:
            return state;
    }
};
