var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { generateScenes } from "../actions/canvas/canvas";
import { clone } from "../../utils/clone";
export var MAX_SCALE = 5;
export var MIN_SCALE = 0.2;
// Значения по умолчанию для сохранения в браузере
var scale = localStorage.getItem('canvas-scale');
var cameraOffsetX = localStorage.getItem('canvas-cameraOffset-x');
var cameraOffsetY = localStorage.getItem('canvas-cameraOffset-y');
if (!cameraOffsetX)
    localStorage.setItem('canvas-cameraOffset', "0");
if (!cameraOffsetY)
    localStorage.setItem('canvas-cameraOffset', "0");
if (!scale)
    localStorage.setItem('canvas-scale', "1");
var initialState = {
    scenes: __spreadArray([], __read(generateScenes(3)), false),
    activeSceneId: 0,
    instrument: 'glove',
    selectedItem: null,
    editableItem: null,
    menuVisible: false,
    currentStage: null,
    hiddenContextMenuMode: true,
    initialFingersDist: 0,
    exportMode: false,
    pencilAttrs: {
        strokeWidth: 4,
        stroke: '#000000',
    },
    lineAttrs: {
        strokeWidth: 4,
        stroke: '#000000',
    },
    noteAttrs: {
        color: '#000000',
        fontFamily: 'Regular',
        fontSize: 20
    },
    eraserTrashCan: [],
    rectAttrs: {
        stroke: '#ccc',
        fill: '#fff',
        dash: [10, 10]
    },
    isMicrophoneActive: false,
    microphonesLabelVisible: true,
    isCameraActive: false,
    isCameraPaused: false,
    startX: 0, startY: 0,
    isPressed: false,
    scale: Number(scale) || 1,
    cameraOffset: {
        x: Number(cameraOffsetX),
        y: Number(cameraOffsetY)
    }
};
export var SET_CANVAS = 'SET_CANVAS';
export var SET_INSTRUMENT = 'SET_INSTRUMENT';
export var SET_STARTING_COORDINATES = 'SET_STARTING_COORDINATES';
export var SET_CAMERA_OFFSET = 'SET_CAMERA_OFFSET';
export var SET_PRESSING_MODE = 'SET_PRESSING_MODE';
export var SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export var UPDATE_SCENES = 'UPDATE_SCENES';
export var SET_CANVAS_SCALE = 'SET_CANVAS_SCALE';
export var CHANGE_PAGE = 'CHANGE_PAGE';
export var RESAVE_CANVAS_STORE = 'RESAVE_CANVAS_STORE';
export var SHOW_CANVAS_MENU = 'SHOW_CANVAS_MENU';
export var HIDE_CANVAS_MENU = 'HIDE_CANVAS_MENU';
export var ADD_NEW_SCENE = 'ADD_NEW_SCENE';
export var SET_CURRENT_STAGE = 'SET_CURRENT_STAGE';
export var SET_RECT_ATTR = 'SET_RECT_ATTR';
export var SET_PENCIL_INSTRUMENT_ATTR = 'SET_PENCIL_INSTRUMENT_ATTR';
export var SET_LINE_INSTRUMENT_ATTR = 'SET_LINE_INSTRUMENT_ATTR';
export var SET_ERASER_TRASH_CAN = 'SET_ERASER_TRASH_CAN';
export var SET_HIDDEN_CONTEXT_MENU_MODE = 'SET_HIDDEN_CONTEXT_MENU_MODE';
export var SET_MICROPHONE_ACTIVE_MODE = 'SET_MICROPHONE_ACTIVE_MODE';
export var SET_CAMERA_ACTIVE_MODE = 'SET_CAMERA_ACTIVE_MODE';
export var SET_CAMERA_PAUSED_MODE = 'SET_CAMERA_PAUSED_MODE';
export var SET_MICROPHONES_LABEL_VISIBLE = 'SET_MICROPHONES_LABEL_VISIBLE';
export var SET_INITIAL_FINGER_DIST = 'SET_INITIAL_FINGER_DIST';
export var SET_EXPORT_MODE = 'SET_EXPORT_MODE';
export var SET_EDITABLE_ITEM = 'SET_EDITABLE_ITEM';
export var SET_NOTE_ATTRIBUTES = 'SET_NOTE_ATTRIBUTES';
export var canvasReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_CANVAS:
            var scene = {
                stage: action.payload.stage,
                canvas: action.payload.canvas,
                ctx: action.payload.ctx,
                canvasID: 0,
                history: [],
                background: null,
                curves: [],
                images: [],
                voiceRecords: [],
                lines: [],
                notes: []
            };
            return __assign(__assign({}, state), { scenes: [scene] });
        case RESAVE_CANVAS_STORE:
            return action.payload;
        case CHANGE_PAGE:
            return __assign(__assign({}, state), { activeSceneId: action.payload });
        case SET_EXPORT_MODE:
            return __assign(__assign({}, state), { exportMode: action.payload });
        case SET_PENCIL_INSTRUMENT_ATTR:
            return __assign(__assign({}, state), { pencilAttrs: action.payload });
        case SET_NOTE_ATTRIBUTES:
            return __assign(__assign({}, state), { noteAttrs: action.payload });
        case SET_LINE_INSTRUMENT_ATTR:
            return __assign(__assign({}, state), { lineAttrs: action.payload });
        case SHOW_CANVAS_MENU:
            return __assign(__assign({}, state), { menuVisible: true });
        case HIDE_CANVAS_MENU:
            return __assign(__assign({}, state), { menuVisible: false });
        case SET_CAMERA_ACTIVE_MODE:
            return __assign(__assign({}, state), { isCameraActive: action.payload });
        case SET_MICROPHONES_LABEL_VISIBLE:
            return __assign(__assign({}, state), { microphonesLabelVisible: action.payload });
        case SET_CAMERA_PAUSED_MODE:
            return __assign(__assign({}, state), { isCameraPaused: action.payload });
        case UPDATE_SCENES:
            return __assign(__assign({}, state), { scenes: action.payload });
        case SET_ERASER_TRASH_CAN:
            return __assign(__assign({}, state), { eraserTrashCan: action.payload });
        case SET_HIDDEN_CONTEXT_MENU_MODE:
            return __assign(__assign({}, state), { hiddenContextMenuMode: action.payload });
        case SET_CURRENT_STAGE:
            return __assign(__assign({}, state), { currentStage: action.payload });
        case ADD_NEW_SCENE:
            return __assign(__assign({}, state), { scenes: __spreadArray(__spreadArray([], __read(clone(state.scenes)), false), [action.payload], false) });
        case SET_MICROPHONE_ACTIVE_MODE:
            return __assign(__assign({}, state), { isMicrophoneActive: action.payload });
        case SET_SELECTED_ITEM:
            return __assign(__assign({}, state), { selectedItem: action.payload });
        case SET_EDITABLE_ITEM:
            return __assign(__assign({}, state), { editableItem: action.payload });
        case SET_INSTRUMENT:
            return __assign(__assign({}, state), { instrument: action.payload });
        case SET_STARTING_COORDINATES:
            return __assign(__assign({}, state), { startX: action.payload.x, startY: action.payload.y });
        case SET_PRESSING_MODE:
            return __assign(__assign({}, state), { isPressed: action.payload });
        case SET_CANVAS_SCALE:
            return __assign(__assign({}, state), { scale: action.payload });
        case SET_RECT_ATTR:
            return __assign(__assign({}, state), { rectAttrs: action.payload });
        case SET_CAMERA_OFFSET:
            return __assign(__assign({}, state), { cameraOffset: {
                    x: action.payload.x,
                    y: action.payload.y,
                } });
        default:
            return state;
    }
};
