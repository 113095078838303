var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import clsx from "clsx";
import { hideCanvasMenu } from "../../../../../../../../../../redux/actions/canvas/canvas";
import store from "../../../../../../../../../../redux/store";
import { LoadProjectButton } from "./components/LoadProjectButton";
import { SaveProjectButton } from "./components/SaveProjectButton";
import { CleanCanvas } from "./components/ClearCanvas";
import { RemovePage } from "./components/RemovePage";
import { ImportPDF } from "./components/ImportPDF";
import { RemoveBackground } from "./components/RemoveBackground";
import { ExportPNG } from "./components/ExportPNG";
import { ExportPDF } from "./components/ExportPDF";
import { CreateEmptyPage } from "./components/CreateEmptyPage";
export var Menu = function () {
    var _a = useSelector(function (state) { return state.canvas; }), menuVisible = _a.menuVisible, scenes = _a.scenes, activeSceneId = _a.activeSceneId;
    var menuRef = useRef();
    var showState = function () {
        var canvas = store.getState().canvas;
        console.log(canvas);
        hideCanvasMenu();
    };
    useEffect(function () {
        var cb = function (event) {
            if (menuRef.current
                && !menuRef.current.contains(event.target)
                && event.target.id !== 'open-menu') {
                hideCanvasMenu();
            }
        };
        if (menuVisible) {
            document.addEventListener('click', cb);
        }
        else {
            document.removeEventListener('click', cb);
        }
        return function () {
            document.removeEventListener('click', cb);
        };
    }, [menuVisible]);
    return (_jsx("div", __assign({ ref: menuRef, className: clsx("canvas-menu absolute", {
            'hidden': !menuVisible
        }) }, { children: _jsxs("ul", { children: [_jsx(LoadProjectButton, {}), _jsx(SaveProjectButton, {}), _jsx(CreateEmptyPage, {}), _jsx(ExportPDF, {}), !scenes[activeSceneId].background
                    ? _jsx(ImportPDF, {})
                    : _jsx(RemoveBackground, {}), _jsx(ExportPNG, {}), scenes.length > 3 ? _jsx(RemovePage, {}) : '', _jsx(CleanCanvas, {})] }) })));
};
