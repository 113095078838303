var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CanvasArea } from "./components/canvas/Canvas";
import { Footer } from "./components/footer/Footer";
import { Header } from "./components/header/Header";
export var Home = function () {
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "coloring-home-page w-full flex flex-column hidden relative" }, { children: [_jsx(Header, {}), _jsx("main", __assign({ className: "coloring-home-page-canvas-area relative" }, { children: _jsx(CanvasArea, {}) })), _jsx(Footer, {})] })) }));
};
