var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useSelector } from 'react-redux';
import jsPDF from "jspdf";
import clsx from "clsx";
import { images } from "../../../../../../../../../../../assets/images/images";
import { addRectBorder, changePage, getCurrentStage, hideCanvasMenu, removeRectBorder, setExportMode, setScale } from '../../../../../../../../../../../redux/actions/canvas/canvas';
export var ExportPDF = function () {
    var _a = __read(useState(false), 2), modalVisible = _a[0], setModalVisible = _a[1];
    var _b = __read(useState(0), 2), modalProgress = _b[0], setModalProgress = _b[1];
    var _c = useSelector(function (state) { return state.canvas; }), scale = _c.scale, cameraOffset = _c.cameraOffset, scenes = _c.scenes;
    var getRectCoordinates = function (stage) {
        if (stage) {
            var layers = stage.getLayers();
            for (var i = 0; i < layers.length; i++) {
                var layer = layers[i];
                var rect = layer.findOne('Rect');
                var _a = rect.position(), x = _a.x, y = _a.y;
                var distanceFromLeft = x + cameraOffset.x;
                var distanceFromTop = y + cameraOffset.y;
                var rectWidth = rect.attrs.width * 1;
                var rectHeight = rect.attrs.height * 1;
                if (rect) {
                    return {
                        x: distanceFromLeft,
                        y: distanceFromTop,
                        width: rectWidth,
                        height: rectHeight,
                    };
                }
            }
        }
        return null;
    };
    var exportPDFProject = function () { return __awaiter(void 0, void 0, void 0, function () {
        var prevScale, stage, rect, x, y, width_1, height_1, pdf_1, pagesNumber_2, counter, progress, imageDataURL, _loop_1, pagesNumber_1, pagesNumber_1_1, pageNumber, e_1_1, img_1;
        var e_1, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    prevScale = scale;
                    setModalVisible(true);
                    setExportMode(true);
                    removeRectBorder();
                    setScale(1);
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 100); })];
                case 1:
                    _b.sent();
                    stage = getCurrentStage();
                    if (!stage) return [3 /*break*/, 10];
                    rect = getRectCoordinates(stage);
                    if (!rect) return [3 /*break*/, 10];
                    x = rect.x, y = rect.y, width_1 = rect.width, height_1 = rect.height;
                    pdf_1 = new jsPDF(width_1 > height_1 ? 'l' : 'p', 'px', width_1 > height_1 ? [width_1, height_1] : [width_1, height_1]);
                    pagesNumber_2 = new Array(scenes.length).fill(0).map(function (_, i) { return i; });
                    counter = 0;
                    progress = 100 / pagesNumber_2.length;
                    imageDataURL = 'imageDataUrl';
                    _loop_1 = function (pageNumber) {
                        var stage_1, dataURL, img_2;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    changePage(pageNumber);
                                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 500); })];
                                case 1:
                                    _c.sent();
                                    stage_1 = getCurrentStage();
                                    if (!stage_1) return [3 /*break*/, 3];
                                    dataURL = stage_1.toDataURL({
                                        pixelRatio: 2,
                                        x: x,
                                        y: y,
                                        width: width_1,
                                        height: height_1,
                                    });
                                    img_2 = new Image();
                                    img_2.onload = function () {
                                        return __awaiter(this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                pdf_1.addImage(img_2, 'JPEG', 0, 0, width_1, height_1);
                                                if (pageNumber !== pagesNumber_2.length - 1) {
                                                    pdf_1.addPage();
                                                }
                                                return [2 /*return*/];
                                            });
                                        });
                                    };
                                    if (pageNumber === pagesNumber_2.length - 1) {
                                        imageDataURL = dataURL;
                                    }
                                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 100); })];
                                case 2:
                                    _c.sent();
                                    img_2.src = dataURL;
                                    counter += progress;
                                    setModalProgress(counter);
                                    _c.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    };
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 7, 8, 9]);
                    pagesNumber_1 = __values(pagesNumber_2), pagesNumber_1_1 = pagesNumber_1.next();
                    _b.label = 3;
                case 3:
                    if (!!pagesNumber_1_1.done) return [3 /*break*/, 6];
                    pageNumber = pagesNumber_1_1.value;
                    return [5 /*yield**/, _loop_1(pageNumber)];
                case 4:
                    _b.sent();
                    _b.label = 5;
                case 5:
                    pagesNumber_1_1 = pagesNumber_1.next();
                    return [3 /*break*/, 3];
                case 6: return [3 /*break*/, 9];
                case 7:
                    e_1_1 = _b.sent();
                    e_1 = { error: e_1_1 };
                    return [3 /*break*/, 9];
                case 8:
                    try {
                        if (pagesNumber_1_1 && !pagesNumber_1_1.done && (_a = pagesNumber_1.return)) _a.call(pagesNumber_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                    return [7 /*endfinally*/];
                case 9:
                    img_1 = new Image();
                    img_1.onload = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        pdf_1.addImage(img_1, 'JPEG', 0, 0, width_1, height_1);
                                        // await new Promise(resolve => setTimeout(resolve, 100))
                                        // removePage(pdf, pagesNumber.length)
                                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 100); })];
                                    case 1:
                                        // await new Promise(resolve => setTimeout(resolve, 100))
                                        // removePage(pdf, pagesNumber.length)
                                        _a.sent();
                                        pdf_1.save('pdf_project.pdf');
                                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 100); })
                                            // Показываем снова границу прямоугольника для рисования
                                        ];
                                    case 2:
                                        _a.sent();
                                        // Показываем снова границу прямоугольника для рисования
                                        addRectBorder();
                                        // Убираем модалу
                                        setModalVisible(false);
                                        setModalProgress(0);
                                        setExportMode(false);
                                        // Меняем на предыдущее значение 
                                        setScale(prevScale);
                                        hideCanvasMenu();
                                        return [2 /*return*/];
                                }
                            });
                        });
                    };
                    img_1.src = imageDataURL;
                    _b.label = 10;
                case 10: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsxs("li", __assign({ onClick: exportPDFProject }, { children: [_jsx("img", { src: images.exportPDF, alt: "canvas-menu-img" }), _jsx("span", { children: "Export project as PDF" })] })), _jsxs("div", __assign({ className: clsx('pdf-loader-modal z-3', {
                    'hidden': !modalVisible
                }) }, { children: [_jsx("span", { children: "Preparing PDF export" }), _jsxs("h3", { children: [modalProgress.toFixed(0), "%"] })] }))] }));
};
