import { setCameraOffset, setPressingMode, setStartingCoordinates } from "../../../redux/actions/canvas/canvas";
import store from "../../../redux/store";
export var start = function (event) {
    setPressingMode(true);
    var _a = event.target.getStage().getPointerPosition(), x = _a.x, y = _a.y;
    setStartingCoordinates(x, y);
};
export var move = function (event) {
    var _a = store.getState().canvas, isPressed = _a.isPressed, startX = _a.startX, startY = _a.startY, cameraOffset = _a.cameraOffset;
    if (!isPressed)
        return;
    var _b = event.target.getStage().getPointerPosition(), x = _b.x, y = _b.y;
    // Рассчитываем разницу между текущим положением мыши и начальной точкой нажатия
    var diffX = x - startX;
    var diffY = y - startY;
    // Добавляем разницу к cameraOffset
    setCameraOffset(cameraOffset.x + diffX, cameraOffset.y + diffY);
    setStartingCoordinates(x, y);
};
export var finish = function (event) {
    setPressingMode(false);
};
