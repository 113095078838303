var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { setUnhiddenContextMenuMode } from "../../../../../../../../../../redux/actions/canvas/canvas";
import { UploadImage } from "./components/UploadImage";
import { GenerateImage } from "./components/GenerateImage";
import { PinImage } from "./components/PinImage";
export var GalleryContext = function () {
    var _a = useSelector(function (state) { return state.canvas; }), instrument = _a.instrument, hiddenContextMenuMode = _a.hiddenContextMenuMode;
    return (_jsxs("div", __assign({ className: clsx("context-canvas-menu", {
            'hidden': instrument !== 'gallery' || !hiddenContextMenuMode
        }) }, { children: [_jsx(UploadImage, {}), _jsx(GenerateImage, {}), _jsx(PinImage, {}), _jsxs("button", __assign({ onClick: function () { return setUnhiddenContextMenuMode(); }, className: "hide-context-button" }, { children: [_jsx("i", { className: "pi pi-chevron-left" }), _jsx("span", { children: "Hide settings" })] }))] })));
};
