var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
import { setHiddenContextMenuMode } from "../../../../../../../../../redux/actions/canvas/canvas";
import { useSelector } from "react-redux";
export var HiddenContextToggle = function () {
    var _a = useSelector(function (state) { return state.canvas; }), instrument = _a.instrument, hiddenContextMenuMode = _a.hiddenContextMenuMode;
    var instruments = ['pencil', 'microphone', 'camera', 'line', 'gallery', 'note'];
    return (_jsx("button", __assign({ onClick: function () { return setHiddenContextMenuMode(); }, className: clsx('open-context', { 'hidden': !instruments.includes(instrument) || hiddenContextMenuMode }) }, { children: _jsx("i", { className: "pi pi-chevron-right" }) })));
};
