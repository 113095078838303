import { combineReducers } from 'redux';
import { themesReducer } from './themes';
import { notificationsReducer } from './notifications';
import { languagesReducer } from './languages';
import { authReducer } from './auth';
import { canvasReducer } from './canvas';
export default combineReducers({
    themes: themesReducer,
    notifications: notificationsReducer,
    languages: languagesReducer,
    auth: authReducer,
    canvas: canvasReducer,
});
