var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Image } from "react-konva";
import { addHistory, cancelSelectedItem, resaveImage, setEraserTrash, setSelectedItem } from "../../../../../../../redux/actions/canvas/canvas";
import { useSelector } from "react-redux";
export var CanvasImage = function (_a) {
    var image = _a.image;
    var _b = useSelector(function (state) { return state; }).canvas, instrument = _b.instrument, selectedItem = _b.selectedItem, eraserTrashCan = _b.eraserTrashCan, isPressed = _b.isPressed;
    var onImageTransformEnd = function (event) {
        addHistory();
        var x = event.target.x();
        var y = event.target.y();
        var rotation = event.target.rotation();
        var scaleX = event.target.scaleX();
        var scaleY = event.target.scaleY();
        var height = event.target.height();
        var width = event.target.width();
        resaveImage(image.id, x, y, rotation, scaleX, scaleY, height, width);
        cancelSelectedItem();
    };
    var onImageDragEnd = function (event) {
        addHistory();
        var x = event.target.x();
        var y = event.target.y();
        resaveImage(image.id, x, y);
        cancelSelectedItem();
    };
    var onImageClick = function (event, selectedItemId, itemId) {
        if (!['cursor', 'gallery'].includes(instrument))
            return;
        event.currentTarget.moveToTop();
        if (itemId === selectedItemId)
            return cancelSelectedItem();
        setSelectedItem({ type: 'image', id: itemId, ref: event.target });
    };
    // Добавляем элемент с список для удаления
    var setTrashedItem = function (image) {
        if (image.pinned)
            return false;
        if (!eraserTrashCan.includes(image.id)) {
            setEraserTrash(__spreadArray(__spreadArray([], __read(eraserTrashCan), false), [image.id], false));
        }
    };
    return (_jsx(Image, { opacity: eraserTrashCan.includes(image.id) ? 0.4 : 1, scaleX: image.scaleX, scaleY: image.scaleY, rotation: image.rotation, onPointerOver: instrument === 'eraser' && isPressed ? function () { return setTrashedItem(image); } : undefined, x: image.x, height: image.height, width: image.width, y: image.y, id: "image-" + image.id, image: image.image, draggable: ['cursor', 'gallery'].includes(instrument) && (selectedItem && selectedItem.id === image.id), onPointerClick: function (event) { return onImageClick(event, selectedItem ? selectedItem.id : '', image.id); }, onDragEnd: function (event) { return onImageDragEnd(event); }, onTransformEnd: function (event) { return onImageTransformEnd(event); } }));
};
