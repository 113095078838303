var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet } from "react-router-dom";
import { ScrollToTop } from "../components/utils/ScrollToTop";
import { Header } from "../components/particals/header/Header";
export var MainLayout = function () { return (_jsxs("div", __assign({ className: "min-h-screen flex flex-column relative flex-auto" }, { children: [_jsx(Header, {}), _jsx(ScrollToTop, {}), _jsx(Outlet, {})] }))); };
