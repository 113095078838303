var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { images } from "../../../../../../../../../../../assets/images/images";
import { hideCanvasMenu, setExportMode } from "../../../../../../../../../../../redux/actions/canvas/canvas";
import store from "../../../../../../../../../../../redux/store";
import { clone } from "../../../../../../../../../../../utils/clone";
import { imageToJSON } from "../../../../../../../../../../../utils/image";
export var SaveProjectButton = function () {
    var blobToBase64 = function (blob) {
        return new Promise(function (resolve, reject) {
            var reader = new FileReader();
            reader.onerror = reject;
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });
    };
    var saveRecordingsToJSON = function (recordings) { return __awaiter(void 0, void 0, void 0, function () {
        var jsonReadyRecordings;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all(recordings.map(function (recording) { return __awaiter(void 0, void 0, void 0, function () {
                        var response, blob, base64audio;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, fetch(recording.url)];
                                case 1:
                                    response = _a.sent();
                                    return [4 /*yield*/, response.blob()];
                                case 2:
                                    blob = _a.sent();
                                    return [4 /*yield*/, blobToBase64(blob)];
                                case 3:
                                    base64audio = _a.sent();
                                    return [2 /*return*/, __assign(__assign({}, recording), { url: base64audio })];
                            }
                        });
                    }); }))];
                case 1:
                    jsonReadyRecordings = _a.sent();
                    return [4 /*yield*/, jsonReadyRecordings];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var saveProject = function () { return __awaiter(void 0, void 0, void 0, function () {
        var scenes, cloned, prepared, prepared_1, prepared_1_1, scene, preparedVoiceRecords, e_1_1, data, blob, url, link;
        var e_1, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    scenes = store.getState().canvas.scenes;
                    cloned = clone(scenes);
                    setExportMode(true);
                    prepared = cloned.map(function (scene) {
                        scene.history = [];
                        scene.images = scene.images.map(function (image) {
                            var imageJSON = imageToJSON(image.image);
                            image = __assign(__assign({}, image), { image: imageJSON.data, width: imageJSON.width, height: imageJSON.height });
                            return image;
                        });
                        if (scene.background) {
                            var data_1 = imageToJSON(scene.background.image).data;
                            scene.background = __assign(__assign({}, scene.background), { image: data_1 });
                        }
                        return scene;
                    });
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 6, 7, 8]);
                    prepared_1 = __values(prepared), prepared_1_1 = prepared_1.next();
                    _b.label = 2;
                case 2:
                    if (!!prepared_1_1.done) return [3 /*break*/, 5];
                    scene = prepared_1_1.value;
                    return [4 /*yield*/, saveRecordingsToJSON(scene.voiceRecords)];
                case 3:
                    preparedVoiceRecords = _b.sent();
                    scene.voiceRecords = preparedVoiceRecords;
                    _b.label = 4;
                case 4:
                    prepared_1_1 = prepared_1.next();
                    return [3 /*break*/, 2];
                case 5: return [3 /*break*/, 8];
                case 6:
                    e_1_1 = _b.sent();
                    e_1 = { error: e_1_1 };
                    return [3 /*break*/, 8];
                case 7:
                    try {
                        if (prepared_1_1 && !prepared_1_1.done && (_a = prepared_1.return)) _a.call(prepared_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                    return [7 /*endfinally*/];
                case 8:
                    data = JSON.stringify(prepared, null, 2);
                    blob = new Blob([data], { type: 'application/json' });
                    url = URL.createObjectURL(blob);
                    link = document.createElement('a');
                    link.href = url;
                    link.download = 'project.coloring';
                    link.click();
                    setExportMode(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onSaveProject = function () {
        saveProject();
        hideCanvasMenu();
    };
    return (_jsxs("li", __assign({ onClick: onSaveProject }, { children: [_jsx("img", { src: images.load, alt: "canvas-menu-img" }), _jsx("span", { children: "Save project as ..." })] })));
};
