var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { addHistory, addImage, getCurrentScene, removeSelectedItem, returnPrevious, scaleDecrease, scaleIncrease, scaleWheelDecrease, scaleWheelIncrease, setCameraOffset, setCanvas, setInstrument, setPressingMode } from "../../redux/actions/canvas/canvas";
import { onFinish, onMove, onStart } from "../instruments/InstrumensService";
import store from "../../redux/store";
var initialDist = 0;
var previousCenter = null;
var CanvasService = /** @class */ (function () {
    function CanvasService() {
        this.initialize = function (scene) {
            var stage = scene.stage;
            if (stage) {
                var _a = stage.bufferCanvas.context, ctx = _a._context, canvas = _a.canvas;
                if (ctx) {
                    setCanvas({ stage: stage, canvas: canvas, ctx: ctx });
                }
            }
        };
    }
    return CanvasService;
}());
export { CanvasService };
export var openContextMenu = function () {
    setPressingMode(false);
};
export var getEventLocation = function (e) {
    if (e.touches && e.touches.length == 1) {
        return { x: e.touches[0].clientX, y: e.touches[0].clientY };
    }
    return { x: e.clientX, y: e.clientY };
};
export var startDrawing = function (event) { return onStart(event); };
export var drawProccess = function (event) { return onMove(event); };
export var endDrawing = function (event) { return onFinish(event); };
export var pasteHanlder = function (event) {
    var items = (event.clipboardData || event.originalEvent.clipboardData).items;
    var _loop_1 = function (i) {
        if (items[i].type.indexOf("image") === 0) {
            // получаем Blob изображения
            var blob = items[i].getAsFile();
            // создаем новый URL-объект для нашего Blob
            var URLObj = window.URL || window.webkitURL;
            var source = URLObj.createObjectURL(blob);
            // создаем новый HTML-элемент изображения
            var pastedImage_1 = new Image();
            pastedImage_1.src = source;
            pastedImage_1.onload = function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        addHistory();
                        addImage(pastedImage_1);
                        setInstrument('cursor');
                        return [2 /*return*/];
                    });
                });
            };
            return "break";
        }
    };
    for (var i = 0; i < items.length; i++) {
        var state_1 = _loop_1(i);
        if (state_1 === "break")
            break;
    }
};
export var panningAndZoom = function (event) {
    event.evt.preventDefault();
    var camera = store.getState().canvas.cameraOffset;
    var pos = event.target.getStage().getPointerPosition();
    if ((event.evt.metaKey || event.evt.ctrlKey))
        return event.evt.deltaY < 0 ? scaleWheelIncrease(pos.x, pos.y) : scaleWheelDecrease(pos.x, pos.y);
    var coef = 0.5;
    var posX = camera.x - event.evt.deltaX * coef;
    var posY = camera.y - event.evt.deltaY * coef;
    setCameraOffset(posX, posY);
};
// export const handleTouchStart = (event: any) => {
//   event.evt.preventDefault()
//   if (event.evt.touches.length === 2) {
//     const { clientX: x1, clientY: y1 } = event.evt.touches[0]
//     const { clientX: x2, clientY: y2 } = event.evt.touches[1]
//     initialDist = Math.hypot(x2 - x1, y2 - y1)
//   }
// }
// export const handleTouchMove = (event: any) => {
//   event.evt.preventDefault();
//   if (event.evt.touches.length === 2) {
//     const { clientX: x1, clientY: y1 } = event.evt.touches[0];
//     const { clientX: x2, clientY: y2 } = event.evt.touches[1];
//     const dist = Math.hypot(x2 - x1, y2 - y1);
//     const centerX = (x1 + x2) / 2;
//     const centerY = (y1 + y2) / 2;
//     // Панорамирование
//     const { canvas: { cameraOffset: camera } }: RootState = store.getState();
//     if (previousCenter) {
//       const deltaX = centerX - previousCenter.x;
//       const deltaY = centerY - previousCenter.y;
//       setCameraOffset(camera.x - deltaX, camera.y - deltaY);
//     }
//     previousCenter = { x: centerX, y: centerY };
//     // Изменение масштаба
//     if (initialDist - dist > 0) {
//       scaleWheelDecrease(centerX, centerY);
//     } else if (initialDist - dist < 0) {
//       scaleWheelIncrease(centerX, centerY);
//     }
//     initialDist = dist;
//   } else {
//     previousCenter = null;
//   }
// };
export var keyboardHandler = function (event) {
    // Разрешить комбинации клавиш
    if ((event.metaKey || event.ctrlKey) && ['V', 'v'].includes(event.key))
        return false;
    if ((event.metaKey || event.ctrlKey) && ['R', 'r'].includes(event.key))
        return false;
    var selectedItem = store.getState().canvas.selectedItem;
    if (selectedItem) {
        if (event.code === "Backspace" || event.key === "Backspace") {
            event.preventDefault();
            removeSelectedItem();
        }
    }
    if ((event.metaKey || event.ctrlKey) && ['Z', 'z'].includes(event.key)) {
        event.preventDefault();
        var scene = getCurrentScene();
        if (scene && scene.history.length > 0)
            returnPrevious();
    }
    // Комбинации клавиш cmd + '+/-' или ctrl + '+/-'
    var isCtrlOrCmd = event.ctrlKey || event.metaKey;
    if (isCtrlOrCmd && ['-', '='].includes(event.key)) {
        event.preventDefault();
        if (['='].includes(event.key))
            scaleIncrease();
        if (['-'].includes(event.key))
            scaleDecrease();
    }
};
