var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { v4 as uuidv4 } from 'uuid';
import { addHistory, cancelEditableItem, cancelSelectedItem, findNoteById, getCurrentScene, setEditableItem, setNotes } from "../../../redux/actions/canvas/canvas";
import store from "../../../redux/store";
import { getDimensionsByNote } from '../../../shared/pages/components/home/components/elements/note/Note';
var findNoteByCoordinates = function (x, y) {
    var e_1, _a;
    var scene = getCurrentScene();
    if (scene) {
        try {
            for (var _b = __values(scene.notes), _c = _b.next(); !_c.done; _c = _b.next()) {
                var note = _c.value;
                var _d = __read(note.points, 2), startX = _d[0], startY = _d[1];
                var _e = note, width = _e.width, height = _e.height;
                var endX = startX + width;
                var endY = startY + height;
                if (x >= startX && x <= endX && y >= startY && y <= endY) {
                    return findNoteById(note.id);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    return null;
};
export var start = function (event) {
    addHistory();
    var scene = getCurrentScene();
    var _a = store.getState().canvas, cameraOffset = _a.cameraOffset, scale = _a.scale, noteAttrs = _a.noteAttrs, selectedItem = _a.selectedItem, editableItem = _a.editableItem;
    if (editableItem && editableItem.tool === 'note') {
        return cancelEditableItem();
    }
    if (selectedItem && selectedItem.tool === 'note')
        return false;
    if (scene) {
        var notes = scene.notes;
        var pos = event.target.getStage().getPointerPosition();
        var adjustedPos = {
            x: (pos.x - cameraOffset.x) / scale,
            y: (pos.y - cameraOffset.y) / scale
        };
        // Если нажали на то место, где уже есть note, то выбираем ее
        // const candidate = findNoteByCoordinates(adjustedPos.x, adjustedPos.y)
        // if (candidate) {
        //   event.evt.stopPropagation()
        //   setSelectedItem({ type: 'note', id: candidate.id, ref: event.target })
        //   setInstrument('cursor')
        //   return false
        // }
        var id = uuidv4();
        var note = {
            id: id,
            tool: 'note',
            rotation: 0,
            content: '',
            points: [adjustedPos.x, adjustedPos.y],
            attr: __assign({}, noteAttrs),
            time: new Date()
        };
        var _b = getDimensionsByNote(note), width = _b.width, height = _b.height;
        note.width = width;
        note.height = height;
        setNotes(__spreadArray(__spreadArray([], __read(notes), false), [note], false));
        setEditableItem(note);
        cancelSelectedItem();
    }
};
