import axios from 'axios';
import store from '../redux/store';
export var setServerResponceLanguage = function () {
    // Set default language
    var reduxStore = store.getState();
    var lang = reduxStore.languages.lang;
    axios.defaults.headers.common['Accept-Language'] = lang;
    // Subscribe to languages toggle
    setLanguageSubscribe();
};
function setLanguageSubscribe() {
    store.subscribe(function () {
        var reduxStore = store.getState();
        var lang = reduxStore.languages.lang;
        axios.defaults.headers.common['Accept-Language'] = lang;
    });
}
