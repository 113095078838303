var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as pdfjsLib from 'pdfjs-dist';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import pdfWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorker;
import { images } from "../../../../../../../../../../../assets/images/images";
import { changePage, generateScenes, hideCanvasMenu, setExportMode, updateScenes } from "../../../../../../../../../../../redux/actions/canvas/canvas";
import { getPDFImage, getPageAmount } from '../../../../../../../../../../../utils/pdf';
import { clone } from '../../../../../../../../../../../utils/clone';
import { rectWidth } from '../../../../../../canvas/Canvas';
var loadImage = function (data) {
    return new Promise(function (resolve) {
        var image = new Image();
        image.onload = function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    resolve(image);
                    return [2 /*return*/];
                });
            });
        };
        image.src = data;
    });
};
// Разбиваем изображение на 3 части: две первые побольше, а последняя будет остатком по высоте
var splitImage = function (image, width, imageHeight) { return __awaiter(void 0, void 0, void 0, function () {
    var coef, partHeight, partHeights, margnPartHeights, parts, i, canvas, ctx, additional, dataURL;
    return __generator(this, function (_a) {
        coef = rectWidth / width;
        partHeight = imageHeight / coef;
        partHeights = [partHeight, partHeight, imageHeight - (partHeight * 2)];
        margnPartHeights = [0, partHeight, partHeight * 2];
        parts = [];
        for (i = 0; i < 3; i++) {
            canvas = document.createElement('canvas');
            ctx = canvas.getContext('2d');
            if (!ctx)
                throw new Error('Unable to get canvas context');
            canvas.width = rectWidth;
            canvas.height = partHeights[i];
            // Вырезание части изображения и отрисовка на временном canvas
            ctx.drawImage(image, 0, margnPartHeights[i], rectWidth, partHeights[i], 0, 0, rectWidth, partHeights[i]);
            additional = i == 2 ? { isLast: true } : {};
            dataURL = canvas.toDataURL();
            parts.push(__assign({ dataURL: dataURL, height: partHeights[i], width: rectWidth, coef: coef }, additional));
        }
        return [2 /*return*/, parts];
    });
}); };
var defaultModalSetting = {
    visible: false,
    callback: function () { }
};
export var ImportPDF = function () {
    var scenes = useSelector(function (state) { return state.canvas; }).scenes;
    var _a = __read(useState(false), 2), modalVisible = _a[0], setModalVisible = _a[1];
    var _b = __read(useState(0), 2), modalProgress = _b[0], setModalProgress = _b[1];
    var _c = __read(useState(defaultModalSetting), 2), modalSettings = _c[0], setModalSettings = _c[1];
    var _d = __read(useState('default-portrait'), 2), importType = _d[0], setImportType = _d[1];
    var recognizeOrientation = function (pdf) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, height, width;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getPDFImage(pdf, 1)];
                case 1:
                    _a = _b.sent(), height = _a.height, width = _a.width;
                    return [2 /*return*/, width > height ? 'landscape' : 'portrait'];
            }
        });
    }); };
    var selectImportType = function (event, type) {
        event.preventDefault();
        event.stopPropagation();
        setImportType(type);
    };
    var loadFile = function () { return __awaiter(void 0, void 0, void 0, function () {
        var input;
        return __generator(this, function (_a) {
            input = document.createElement('input');
            input.type = 'file';
            input.accept = '.pdf';
            input.onchange = function (event) { return __awaiter(void 0, void 0, void 0, function () {
                var _a, file, pageAmount, reader;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = __read(event.target.files, 1), file = _a[0];
                            return [4 /*yield*/, getPageAmount(file)];
                        case 1:
                            pageAmount = _b.sent();
                            if (!pageAmount)
                                return [2 /*return*/, false];
                            if (file) {
                                reader = new FileReader();
                                reader.onload = function (event) { return __awaiter(void 0, void 0, void 0, function () {
                                    var typedarray, loadingTask, pdf_1, orient, pages, pdfScenes, clonesScenes, prevScenesLength, counter, progress, currentProgress, pages_1, pages_1_1, pageNum, _a, data, height, width, image, _b, scene, e_1_1, updScenes, callback, error_1;
                                    var e_1, _c;
                                    return __generator(this, function (_d) {
                                        switch (_d.label) {
                                            case 0:
                                                typedarray = new Uint8Array(event.target.result);
                                                loadingTask = pdfjsLib.getDocument({ data: typedarray });
                                                _d.label = 1;
                                            case 1:
                                                _d.trys.push([1, 15, , 16]);
                                                return [4 /*yield*/, loadingTask.promise];
                                            case 2:
                                                pdf_1 = _d.sent();
                                                return [4 /*yield*/, recognizeOrientation(pdf_1)];
                                            case 3:
                                                orient = _d.sent();
                                                if (!(orient === 'landscape')) return [3 /*break*/, 14];
                                                setExportMode(true);
                                                setModalVisible(true);
                                                pages = new Array(pageAmount).fill(0).map(function (_, i) { return ++i; });
                                                pdfScenes = [];
                                                clonesScenes = clone(scenes);
                                                prevScenesLength = scenes.length;
                                                counter = clonesScenes.length;
                                                progress = 100 / pages.length;
                                                currentProgress = 0;
                                                _d.label = 4;
                                            case 4:
                                                _d.trys.push([4, 11, 12, 13]);
                                                pages_1 = __values(pages), pages_1_1 = pages_1.next();
                                                _d.label = 5;
                                            case 5:
                                                if (!!pages_1_1.done) return [3 /*break*/, 10];
                                                pageNum = pages_1_1.value;
                                                return [4 /*yield*/, getPDFImage(pdf_1, pageNum)];
                                            case 6:
                                                _a = _d.sent(), data = _a.data, height = _a.height, width = _a.width;
                                                return [4 /*yield*/, loadImage(data)];
                                            case 7:
                                                image = _d.sent();
                                                _b = __read(generateScenes(1), 1), scene = _b[0];
                                                scene.canvasID = counter;
                                                scene.background = { image: image, width: width, height: height };
                                                counter++;
                                                currentProgress += progress;
                                                setModalProgress(Number((currentProgress).toFixed(0)));
                                                return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 100); })];
                                            case 8:
                                                _d.sent();
                                                pdfScenes.push(scene);
                                                _d.label = 9;
                                            case 9:
                                                pages_1_1 = pages_1.next();
                                                return [3 /*break*/, 5];
                                            case 10: return [3 /*break*/, 13];
                                            case 11:
                                                e_1_1 = _d.sent();
                                                e_1 = { error: e_1_1 };
                                                return [3 /*break*/, 13];
                                            case 12:
                                                try {
                                                    if (pages_1_1 && !pages_1_1.done && (_c = pages_1.return)) _c.call(pages_1);
                                                }
                                                finally { if (e_1) throw e_1.error; }
                                                return [7 /*endfinally*/];
                                            case 13:
                                                updScenes = __spreadArray(__spreadArray([], __read(clonesScenes), false), __read(pdfScenes), false);
                                                updateScenes(updScenes);
                                                changePage(prevScenesLength);
                                                setModalVisible(false);
                                                setExportMode(false);
                                                setModalProgress(0);
                                                return [2 /*return*/, hideCanvasMenu()];
                                            case 14:
                                                callback = function (event, importType) { return __awaiter(void 0, void 0, void 0, function () {
                                                    var pages, pdfScenes, clonesScenes, prevScenesLength, counter, progress, currentProgress, pages_2, pages_2_1, pageNum, _a, data, height, width, image, parts, parts_1, parts_1_1, partData, dataURL, height_1, width_1, coef, isLast, _b, partScene, partImage, e_2_1, _c, scene, e_3_1, updScenes;
                                                    var e_3, _d, e_2, _e;
                                                    return __generator(this, function (_f) {
                                                        switch (_f.label) {
                                                            case 0:
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                setExportMode(true);
                                                                setModalVisible(true);
                                                                setModalSettings(function (prev) { return (__assign(__assign({}, prev), { visible: false })); });
                                                                pages = new Array(pageAmount).fill(0).map(function (_, i) { return ++i; });
                                                                pdfScenes = [];
                                                                clonesScenes = clone(scenes);
                                                                prevScenesLength = scenes.length;
                                                                counter = clonesScenes.length;
                                                                progress = 100 / pages.length;
                                                                currentProgress = 0;
                                                                _f.label = 1;
                                                            case 1:
                                                                _f.trys.push([1, 18, 19, 20]);
                                                                pages_2 = __values(pages), pages_2_1 = pages_2.next();
                                                                _f.label = 2;
                                                            case 2:
                                                                if (!!pages_2_1.done) return [3 /*break*/, 17];
                                                                pageNum = pages_2_1.value;
                                                                return [4 /*yield*/, getPDFImage(pdf_1, pageNum)];
                                                            case 3:
                                                                _a = _f.sent(), data = _a.data, height = _a.height, width = _a.width;
                                                                return [4 /*yield*/, loadImage(data)];
                                                            case 4:
                                                                image = _f.sent();
                                                                if (!(importType === 'special-portrait')) return [3 /*break*/, 14];
                                                                return [4 /*yield*/, splitImage(image, width, height)];
                                                            case 5:
                                                                parts = _f.sent();
                                                                _f.label = 6;
                                                            case 6:
                                                                _f.trys.push([6, 12, 13, 14]);
                                                                parts_1 = (e_2 = void 0, __values(parts)), parts_1_1 = parts_1.next();
                                                                _f.label = 7;
                                                            case 7:
                                                                if (!!parts_1_1.done) return [3 /*break*/, 11];
                                                                partData = parts_1_1.value;
                                                                dataURL = partData.dataURL, height_1 = partData.height, width_1 = partData.width, coef = partData.coef, isLast = partData.isLast;
                                                                _b = __read(generateScenes(1), 1), partScene = _b[0];
                                                                return [4 /*yield*/, loadImage(dataURL)];
                                                            case 8:
                                                                partImage = _f.sent();
                                                                partScene.canvasID = counter;
                                                                // Здесь используем partHeight, которую объявили выше
                                                                partScene.background = { image: partImage, height: height_1, width: width_1, coef: coef, isLast: isLast, orientation: 'portrait' };
                                                                counter++;
                                                                currentProgress += progress / 3;
                                                                setModalProgress(Number(currentProgress.toFixed(0)));
                                                                return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 100); })];
                                                            case 9:
                                                                _f.sent();
                                                                pdfScenes.push(partScene);
                                                                _f.label = 10;
                                                            case 10:
                                                                parts_1_1 = parts_1.next();
                                                                return [3 /*break*/, 7];
                                                            case 11: return [3 /*break*/, 14];
                                                            case 12:
                                                                e_2_1 = _f.sent();
                                                                e_2 = { error: e_2_1 };
                                                                return [3 /*break*/, 14];
                                                            case 13:
                                                                try {
                                                                    if (parts_1_1 && !parts_1_1.done && (_e = parts_1.return)) _e.call(parts_1);
                                                                }
                                                                finally { if (e_2) throw e_2.error; }
                                                                return [7 /*endfinally*/];
                                                            case 14:
                                                                if (!(importType === 'default-portrait')) return [3 /*break*/, 16];
                                                                _c = __read(generateScenes(1), 1), scene = _c[0];
                                                                scene.canvasID = counter;
                                                                scene.background = { image: image, width: width, height: height };
                                                                counter++;
                                                                currentProgress += progress;
                                                                setModalProgress(Number((currentProgress).toFixed(0)));
                                                                return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 100); })];
                                                            case 15:
                                                                _f.sent();
                                                                pdfScenes.push(scene);
                                                                _f.label = 16;
                                                            case 16:
                                                                pages_2_1 = pages_2.next();
                                                                return [3 /*break*/, 2];
                                                            case 17: return [3 /*break*/, 20];
                                                            case 18:
                                                                e_3_1 = _f.sent();
                                                                e_3 = { error: e_3_1 };
                                                                return [3 /*break*/, 20];
                                                            case 19:
                                                                try {
                                                                    if (pages_2_1 && !pages_2_1.done && (_d = pages_2.return)) _d.call(pages_2);
                                                                }
                                                                finally { if (e_3) throw e_3.error; }
                                                                return [7 /*endfinally*/];
                                                            case 20:
                                                                updScenes = __spreadArray(__spreadArray([], __read(clonesScenes), false), __read(pdfScenes), false);
                                                                updateScenes(updScenes);
                                                                changePage(prevScenesLength);
                                                                setModalVisible(false);
                                                                setExportMode(false);
                                                                setModalProgress(0);
                                                                hideCanvasMenu();
                                                                setModalSettings(defaultModalSetting);
                                                                return [2 /*return*/];
                                                        }
                                                    });
                                                }); };
                                                setModalSettings({
                                                    visible: true,
                                                    callback: callback
                                                });
                                                return [3 /*break*/, 16];
                                            case 15:
                                                error_1 = _d.sent();
                                                console.error('Error while loading PDF:', error_1);
                                                return [3 /*break*/, 16];
                                            case 16: return [2 /*return*/];
                                        }
                                    });
                                }); };
                                reader.readAsArrayBuffer(file);
                            }
                            return [2 /*return*/];
                    }
                });
            }); };
            input.click();
            return [2 /*return*/];
        });
    }); };
    var uploadPDF = function (event) {
        event.preventDefault();
        event.stopPropagation();
        loadFile();
    };
    return (_jsxs(_Fragment, { children: [_jsxs("li", __assign({ onClick: function (event) { return uploadPDF(event); }, className: 'import-pdf-area' }, { children: [_jsx("img", { src: images.importPDF, alt: "canvas-menu-img", className: 'import-pdf-area' }), _jsx("span", __assign({ className: 'import-pdf-area' }, { children: "Import PDF as a background" }))] })), _jsxs("div", __assign({ className: clsx("pdf-loader-modal z-3", {
                    'hidden': !modalVisible
                }) }, { children: [_jsx("span", { children: "PDF import" }), _jsxs("h3", { children: [modalProgress, "%"] })] })), _jsxs(Dialog, __assign({ header: 'Please select type of Portrait Import', visible: modalSettings.visible, headerClassName: "portrait-import-header", resizable: false, className: 'portrait-import-zone', onClick: function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                }, onHide: function () {
                    setModalSettings(defaultModalSetting);
                }, style: { width: '50vw' }, breakpoints: { '960px': '75vw', '641px': '100vw' } }, { children: [_jsxs("div", __assign({ className: "portrait-import-options mb-4" }, { children: [_jsx("div", __assign({ onClick: function (event) { return selectImportType(event, 'default-portrait'); }, className: clsx("portrait-import-option", {
                                    'active': 'default-portrait' === importType
                                }) }, { children: _jsxs("div", __assign({ className: "info" }, { children: [_jsx("span", __assign({ className: "header" }, { children: "Default" })), _jsx("img", { src: images.defaultImport, alt: "defaultImport" }), _jsx("span", __assign({ className: "description" }, { children: "The image of each slide will be displayed on the left side of the gray rectangle of the page." }))] })) })), _jsx("div", __assign({ onClick: function (event) { return selectImportType(event, 'special-portrait'); }, className: clsx("portrait-import-option", {
                                    'active': 'special-portrait' === importType
                                }) }, { children: _jsxs("div", __assign({ className: "info" }, { children: [_jsx("span", __assign({ className: "header" }, { children: "Special" })), _jsx("img", { src: images.specialImport, alt: "specialImport" }), _jsx("span", __assign({ className: "description" }, { children: "The image of each slide will be divided into 3 parts displayed on separate pages." }))] })) }))] })), _jsx(Button, { onClick: function (event) { return modalSettings.callback(event, importType); }, className: "bg-white border-1 border-400 app-button border-round-md w-full", label: 'Import PDF Document' })] }))] }));
};
