var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { images } from "../../../../../../../../../../../assets/images/images";
import { cleanCurrentScene, hideCanvasMenu } from '../../../../../../../../../../../redux/actions/canvas/canvas';
export var CleanCanvas = function () {
    var clean = function () {
        cleanCurrentScene();
        hideCanvasMenu();
    };
    return (_jsx(_Fragment, { children: _jsxs("li", __assign({ className: "clean-page", onClick: clean }, { children: [_jsx("img", { src: images.trashCan, alt: "canvas-menu-img" }), _jsx("span", { children: "Clean page content" })] })) }));
};
