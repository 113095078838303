var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route } from "react-router-dom";
import { Home } from "./components/home/Home";
import { AboutUs } from "./components/about/AboutUs";
import { NotFound } from "./components/not-found/NotFound";
import { PrivateRoute } from "../components/particals/pages/PrivateRoute";
import { MainLayout } from "../layouts/MainLayout";
export var Pages = function () {
    // const { isAuthenticated: auth } = useSelector(state => state.auth)
    return (_jsxs(Routes, { children: [_jsx(Route, { index: true, element: _jsx(Home, {}) }), _jsx(Route, __assign({ path: '/', element: _jsx(PrivateRoute, {}) }, { children: _jsx(Route, __assign({ path: "/", element: _jsx(MainLayout, {}) }, { children: _jsx(Route, { path: "about", element: _jsx(AboutUs, {}) }) })) })), _jsx(Route, { path: "*", element: _jsx(NotFound, {}) })] }));
};
