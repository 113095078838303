var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Scale } from "./componets/Scale";
import { Pages } from "./componets/Pages";
export var Footer = function () {
    return (_jsx("footer", __assign({ className: "app-footer w-full fixed z-1 py-5" }, { children: _jsxs("section", __assign({ className: "w-full flex align-items-center justify-content-between" }, { children: [_jsx(Scale, {}), _jsx("div", __assign({ className: "flex gap-2" }, { children: _jsx(Pages, {}) }))] })) })));
};
