var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import clsx from "clsx";
import { useState } from "react";
import { images } from "../../../../../../../../../../../assets/images/images";
import { addRectBorder, getCurrentStage, hideCanvasMenu, removeRectBorder, setExportMode, setScale } from '../../../../../../../../../../../redux/actions/canvas/canvas';
export var ExportPNG = function () {
    var _a = __read(useState(false), 2), modalVisible = _a[0], setModalVisible = _a[1];
    var _b = __read(useState(0), 2), modalProgress = _b[0], setModalProgress = _b[1];
    var _c = useSelector(function (state) { return state.canvas; }), scale = _c.scale, cameraOffset = _c.cameraOffset;
    // Получаем размеры и координаты начала
    var getRectCoordinates = function (stage) {
        if (stage) {
            var layers = stage.getLayers();
            for (var i = 0; i < layers.length; i++) {
                var layer = layers[i];
                var rect = layer.findOne('Rect');
                var _a = rect.position(), x = _a.x, y = _a.y;
                var distanceFromLeft = x + cameraOffset.x;
                var distanceFromTop = y + cameraOffset.y;
                var rectWidth = rect.attrs.width * 1;
                var rectHeight = rect.attrs.height * 1;
                console.log(rectWidth, rectHeight);
                if (rect) {
                    return {
                        x: distanceFromLeft,
                        y: distanceFromTop,
                        width: rectWidth,
                        height: rectHeight,
                    };
                }
            }
        }
        return null;
    };
    var exportPNG = function () { return __awaiter(void 0, void 0, void 0, function () {
        var prevScale, delay, intervalDuration, counter, intervalId, stage, rect, x, y, width, height, dataURL, link;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    prevScale = scale;
                    setModalVisible(true);
                    removeRectBorder();
                    setExportMode(true);
                    setScale(1);
                    delay = 2000;
                    intervalDuration = delay / 100;
                    counter = 0;
                    intervalId = setInterval(function () {
                        if (counter >= 100) {
                            clearInterval(intervalId);
                            return;
                        }
                        // Ваш код для выполнения в каждом интервале
                        setModalProgress(counter);
                        counter++;
                    }, intervalDuration);
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, delay); })];
                case 1:
                    _a.sent();
                    stage = getCurrentStage();
                    if (!stage) return [3 /*break*/, 3];
                    rect = getRectCoordinates(stage);
                    if (!(stage && rect)) return [3 /*break*/, 3];
                    x = rect.x, y = rect.y, width = rect.width, height = rect.height;
                    dataURL = stage.toDataURL({
                        pixelRatio: 2,
                        x: x,
                        y: y,
                        width: width,
                        height: height,
                    });
                    link = document.createElement('a');
                    link.download = 'png_image';
                    link.href = dataURL;
                    link.click();
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 100); })];
                case 2:
                    _a.sent();
                    addRectBorder();
                    setModalVisible(false);
                    setModalProgress(0);
                    setExportMode(false);
                    // Меняем на предыдущее значение 
                    setScale(prevScale);
                    hideCanvasMenu();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsxs("li", __assign({ onClick: exportPNG }, { children: [_jsx("img", { src: images.exportPNG, alt: "canvas-menu-img" }), _jsx("span", { children: "Export current page as PNG" })] })), _jsxs("div", __assign({ className: clsx('pdf-loader-modal z-3', {
                    'hidden': !modalVisible
                }) }, { children: [_jsx("span", { children: "Preparing PNG export" }), _jsxs("h3", { children: [modalProgress, "%"] })] }))] }));
};
