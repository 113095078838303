var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { images } from "../../../../../../../../../../../assets/images/images";
import { hideCanvasMenu, removeCurrentScene } from "../../../../../../../../../../../redux/actions/canvas/canvas";
export var RemovePage = function () {
    var remove = function () {
        removeCurrentScene();
        hideCanvasMenu();
    };
    return (_jsxs("li", __assign({ onClick: remove }, { children: [_jsx("img", { src: images.clean, alt: "canvas-menu-img" }), _jsx("span", { children: "Remove current page" })] })));
};
