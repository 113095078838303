var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { changeNoteAttributes, findNoteById, replaceNoteById, setUnhiddenContextMenuMode } from "../../../../../../../../../../redux/actions/canvas/canvas";
import store from "../../../../../../../../../../redux/store";
import { getDimensionsByNote } from "../../../../../elements/note/Note";
export var NoteContext = function () {
    var _a = useSelector(function (state) { return state.canvas; }), instrument = _a.instrument, noteAttrs = _a.noteAttrs, hiddenContextMenuMode = _a.hiddenContextMenuMode;
    var colors = [
        '#AD715E',
        '#D06C64',
        '#F93A23',
        '#FB573C',
        '#E04EB0',
        '#FFAC47',
        '#43D693',
        '#17A765',
        '#4987E7',
        '#B99BFF',
        '#000000',
        '#ffffff',
    ];
    var changeAttribute = function (attrName, value) {
        var _a;
        var _b = store.getState().canvas, noteAttrs = _b.noteAttrs, editableItem = _b.editableItem, selectedItem = _b.selectedItem;
        var attributes = Object.assign(noteAttrs, (_a = {}, _a[attrName] = value, _a));
        changeNoteAttributes(attributes);
        if (editableItem && editableItem.tool === 'note') {
            var note = findNoteById(editableItem.id);
            if (note) {
                note.attr = attributes;
                if (attrName === 'fontSize') {
                    var _c = getDimensionsByNote(note), width = _c.width, height = _c.height;
                    note.width = width;
                    note.height = height;
                }
                replaceNoteById(note.id, note);
            }
        }
        if (selectedItem && selectedItem.type === 'note') {
            var note = findNoteById(selectedItem.id);
            if (note) {
                note.attr = attributes;
                if (attrName === 'fontSize') {
                    var _d = getDimensionsByNote(note), width = _d.width, height = _d.height;
                    note.width = width;
                    note.height = height;
                }
                replaceNoteById(note.id, note);
            }
        }
    };
    var changeSize = function (fontSize) {
        changeAttribute('fontSize', fontSize);
    };
    var changeColor = function (color) {
        changeAttribute('color', color);
    };
    var changeFontFamily = function (fontFamily) {
        changeAttribute('fontFamily', fontFamily);
    };
    return (_jsxs("div", __assign({ className: clsx("context-canvas-menu", {
            'hidden': instrument !== 'note' || !hiddenContextMenuMode
        }) }, { children: [_jsxs("div", __assign({ className: 'context-block' }, { children: [_jsx("label", { children: "Size" }), _jsxs("div", __assign({ className: 'elements flex flex-wrap' }, { children: [_jsx("div", __assign({ onClick: function () { return changeSize(20); }, className: clsx('note element', { 'active': noteAttrs.fontSize === 20 }) }, { children: "S" })), _jsx("div", __assign({ onClick: function () { return changeSize(35); }, className: clsx('note element', { 'active': noteAttrs.fontSize === 35 }) }, { children: "M" })), _jsx("div", __assign({ onClick: function () { return changeSize(60); }, className: clsx('note element', { 'active': noteAttrs.fontSize === 60 }) }, { children: "L" }))] }))] })), _jsxs("div", __assign({ className: 'context-block' }, { children: [_jsx("label", { children: "Color" }), _jsx("div", __assign({ className: 'elements flex flex-wrap' }, { children: colors.map(function (color, index) {
                            return (_jsx("div", { style: {
                                    background: color
                                }, onClick: function () { return changeColor(color); }, className: clsx('element color', {
                                    'white': color === '#ffffff',
                                    'active': noteAttrs.color === color
                                }) }, color + index));
                        }) }))] })), _jsxs("div", __assign({ className: 'context-block context-block-note-font-family' }, { children: [_jsx("label", { children: "Font Family" }), _jsxs("div", __assign({ className: 'elements flex' }, { children: [_jsx("div", __assign({ onClick: function () { return changeFontFamily('Regular'); }, className: clsx('note-font-family element', { 'active': noteAttrs.fontFamily === 'Regular' }) }, { children: "Montserrat" })), _jsx("div", __assign({ onClick: function () { return changeFontFamily('Arial'); }, style: { fontFamily: 'Arial' }, className: clsx('note-font-family element', { 'active': noteAttrs.fontFamily === 'Arial' }) }, { children: "Arial" })), _jsx("div", __assign({ onClick: function () { return changeFontFamily('monospace'); }, style: { fontFamily: 'monospace' }, className: clsx('note-font-family element', { 'active': noteAttrs.fontFamily === 'monospace' }) }, { children: "Mono" }))] }))] })), _jsxs("button", __assign({ onClick: function () { return setUnhiddenContextMenuMode(); }, className: "hide-context-button" }, { children: [_jsx("i", { className: "pi pi-chevron-left" }), _jsx("span", { children: "Hide settings" })] }))] })));
};
