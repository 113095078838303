import store from "../../redux/store";
import { start as pencilStart, move as pencilMove, finish as pencilFinish } from "./pencil/Pencil";
import { start as lineStart, move as lineMove, finish as lineFinish } from "./line/Line";
import { start as gloveStart, move as gloveMove, finish as gloveFinish } from "./glove/Glove";
import { start as eraserStart, move as eraserMove, finish as eraserFinish } from "./eraser/Eraser";
import { start as cursorStart, move as cursorMove, finish as cursorFinish } from "./cursor/Cursor";
import { start as noteStart } from "./note/Note";
export var onStart = function (event) {
    var instrument = store.getState().canvas.instrument;
    switch (instrument) {
        case 'cursor': return cursorStart(event);
        case 'pencil': return pencilStart(event);
        case 'line': return lineStart(event);
        case 'glove': return gloveStart(event);
        case 'eraser': return eraserStart(event);
        case 'note': return noteStart(event);
        default: return function () { };
    }
};
export var onMove = function (event) {
    var instrument = store.getState().canvas.instrument;
    switch (instrument) {
        case 'cursor': return cursorMove(event);
        case 'pencil': return pencilMove(event);
        case 'line': return lineMove(event);
        case 'glove': return gloveMove(event);
        case 'eraser': return eraserMove(event);
        default: return function () { };
    }
};
export var onFinish = function (event) {
    var instrument = store.getState().canvas.instrument;
    switch (instrument) {
        case 'cursor': return cursorFinish(event);
        case 'pencil': return pencilFinish(event);
        case 'line': return lineFinish(event);
        case 'glove': return gloveFinish(event);
        case 'eraser': return eraserFinish(event);
        default: return function () { };
    }
};
