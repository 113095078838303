import { cancelEditableItem, removeEmptyNotes } from "../../../redux/actions/canvas/canvas";
import store from "../../../redux/store";
export var start = function (event) {
    var editableItem = store.getState().canvas.editableItem;
    if (editableItem) {
        cancelEditableItem();
        removeEmptyNotes();
    }
};
export var move = function (event) {
};
export var finish = function (event) {
};
