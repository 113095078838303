var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { v4 as uuidv4 } from 'uuid';
import { addHistory, getCurrentScene, setLines, setPressingMode } from "../../../redux/actions/canvas/canvas";
import store from "../../../redux/store";
export var start = function (event) {
    addHistory();
    setPressingMode(true);
    var scene = getCurrentScene();
    var _a = store.getState().canvas, cameraOffset = _a.cameraOffset, scale = _a.scale, lineAttrs = _a.lineAttrs;
    if (scene) {
        var lines = scene.lines;
        var pos = event.target.getStage().getPointerPosition();
        var adjustedPos = {
            x: (pos.x - cameraOffset.x) / scale,
            y: (pos.y - cameraOffset.y) / scale
        };
        var id = uuidv4();
        setLines(__spreadArray(__spreadArray([], __read(lines), false), [{
                id: id,
                tool: 'line',
                points: [adjustedPos.x, adjustedPos.y, adjustedPos.x, adjustedPos.y],
                attr: __assign({}, lineAttrs),
                time: new Date()
            }], false));
    }
};
export var move = function (event) {
    var _a = store.getState().canvas, isPressed = _a.isPressed, cameraOffset = _a.cameraOffset, scale = _a.scale;
    var scene = getCurrentScene();
    if (!isPressed)
        return;
    if (scene) {
        var lines = scene.lines;
        var stage = event.target.getStage();
        var point = stage.getPointerPosition();
        var adjustedPoint = {
            x: (point.x - cameraOffset.x) / scale,
            y: (point.y - cameraOffset.y) / scale
        };
        var lastLine = lines[lines.length - 1];
        // update end of line
        lastLine.points[2] = adjustedPoint.x;
        lastLine.points[3] = adjustedPoint.y;
        // replace last line
        lines[lines.length - 1] = lastLine;
        setLines(lines.concat());
    }
};
export var finish = function (event) {
    setPressingMode(false);
};
