var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from "clsx";
import { InputTextarea } from 'primereact/inputtextarea';
import { useRef, useState } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { generateImages } from "../../../../../../../../../../../api";
import { addImage } from "../../../../../../../../../../../redux/actions/canvas/canvas";
var sizes = ['small', 'medium', 'huge'];
export var GenerateImage = function () {
    var _a = __read(useState(false), 2), modalVisible = _a[0], setModalVisible = _a[1];
    var _b = __read(useState(''), 2), description = _b[0], setDescription = _b[1];
    var _c = __read(useState('small'), 2), size = _c[0], setSize = _c[1];
    var _d = __read(useState([]), 2), images = _d[0], setImages = _d[1];
    var cancelToken = useRef(null);
    var _e = __read(useState(false), 2), loading = _e[0], setLoading = _e[1];
    var hideModal = function () {
        setModalVisible(false);
        setImages([]);
    };
    var generate = function () {
        setLoading(true);
        setModalVisible(true);
        cancelToken.current = axios.CancelToken.source();
        var obj = {
            prompt: description,
            size: size
        };
        generateImages(obj, cancelToken)
            .then(function (responce) {
            var images = responce.data.images;
            setImages(images);
            setLoading(false);
        })
            .catch(function () {
            hideModal();
            setLoading(false);
        });
    };
    var abortRequest = function (event) {
        var _a;
        event.preventDefault();
        (_a = cancelToken.current) === null || _a === void 0 ? void 0 : _a.cancel();
        setLoading(false);
        hideModal();
    };
    var selectImage = function (img) { return __awaiter(void 0, void 0, void 0, function () {
        var image;
        return __generator(this, function (_a) {
            image = new Image();
            image.onload = function () {
                addImage(image);
                hideModal();
            };
            image.src = img.base64;
            return [2 /*return*/];
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'context-block generate-area' }, { children: [_jsxs("label", __assign({ className: "beta-label" }, { children: ["AI Image ", _jsx("span", __assign({ className: "beta" }, { children: "Beta" }))] })), _jsxs("div", { children: [_jsx(InputTextarea, { value: description, placeholder: "Describe an image", onChange: function (event) { return setDescription(event.target.value); }, rows: 3 }), _jsx(Button, { onClick: generate, disabled: !description.trim() || loading, className: clsx('generate-btn', { 'disable': !description.trim() || loading }), label: "Generate" })] })] })), _jsx("div", __assign({ className: clsx('pdf-loader-modal z-3', {
                    'hidden': !modalVisible
                }) }, { children: loading
                    ? (_jsxs("div", __assign({ className: "flex flex-column align-items-center gap-1" }, { children: [_jsx("span", { children: "Generating image..." }), _jsx("button", __assign({ onClick: abortRequest, className: "image-area-button" }, { children: "Cancel generating" }))] })))
                    : (_jsxs("div", __assign({ className: "ai-images-area" }, { children: [_jsx("span", __assign({ className: "m-0" }, { children: "Select image" })), _jsxs("div", __assign({ className: "flex align-items-center gap-2" }, { children: [_jsx("button", __assign({ onClick: generate, className: "image-area-button" }, { children: "Try again!" })), _jsx("button", __assign({ onClick: hideModal, className: "image-area-button" }, { children: "Cancel" }))] })), _jsx("div", __assign({ className: "images-block flex align-items-center justify-content-center gap-2 flex-wrap" }, { children: images.map(function (images, i) {
                                    return (_jsx("img", { onClick: function () { return selectImage(images); }, src: images.url, alt: "ai-image" }, images.url + i));
                                }) }))] }))) }))] }));
};
