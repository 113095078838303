var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Pencil } from "../instruments/pencil/Pencil";
import { Eraser } from "../instruments/eraser/Eraser";
import { Glove } from "../instruments/glove/Glove";
import { Cursor } from "../instruments/cursor/Cursor";
import { MenuButton } from "./components/menu/MenuButton";
import { Changes } from "../footer/componets/Changes";
import { Camera } from "../instruments/camera/Camera";
import { Microphone } from "../instruments/microphone/Microphone";
import { Gallery } from "../instruments/gallery/Gallery";
import { Line } from "../instruments/line/Line";
import { isSafari } from "../../../../../../utils/browsers";
import { Text } from "../instruments/text/Text";
export var Header = function () {
    var isPressed = useSelector(function (state) { return state.canvas; }).isPressed;
    return (_jsx("article", __assign({ className: "app-header w-full absolute z-2 py-5 gap-1" }, { children: _jsxs("section", __assign({ className: "w-full gap-1 flex align-items-center justify-content-between" }, { children: [_jsx(MenuButton, {}), _jsxs("div", __assign({ className: clsx("flex gap-2 menu-instruments", {
                        'pointer-events-all': !isPressed,
                        'pointer-events-none': isPressed
                    }) }, { children: [_jsxs("section", __assign({ className: clsx("pl-1 pr-1 h-2rem gap-2 flex align-items-center justify-content-between app-instruments", { 'pointer-events-none': isPressed }) }, { children: [_jsx(Cursor, {}), _jsx(Glove, {})] })), _jsxs("section", __assign({ className: clsx("pl-1 pr-1 h-2rem gap-2 flex align-items-center justify-content-between app-instruments", { 'pointer-events-none': isPressed }) }, { children: [_jsx(Pencil, {}), _jsx(Line, {}), _jsx(Eraser, {}), _jsx(Text, {}), _jsx(Gallery, {})] })), _jsxs("section", __assign({ className: clsx("pl-1 pr-1 h-2rem gap-2 flex align-items-center justify-content-between app-instruments", {
                                'pointer-events-none': isPressed,
                                'force-hidden': isSafari(),
                            }) }, { children: [_jsx(Camera, {}), _jsx(Microphone, {})] }))] })), _jsx(Changes, {})] })) })));
};
