var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { Menu } from "./components/menu/Menu";
import { hideCanvasMenu, setInstrument, showCanvasMenu } from "../../../../../../../../redux/actions/canvas/canvas";
import { ContextMenu } from "../../../footer/componets/context-menu/ContextMenu";
import clsx from "clsx";
export var MenuButton = function () {
    var _a = useSelector(function (state) { return state.canvas; }), menuVisible = _a.menuVisible, isPressed = _a.isPressed;
    var menuToggle = function () {
        if (!menuVisible) {
            showCanvasMenu();
            return setInstrument('cursor');
        }
        hideCanvasMenu();
    };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "flex" }, { children: [_jsx(Button, { id: "open-menu", onClick: menuToggle, className: clsx("text-green-500 ml-5 pi pi-bars remove-button-text app-button", { 'pointer-events-none': isPressed }) }), _jsx("div", __assign({ className: clsx("menu-area menu-toggle", { 'pointer-events-none': isPressed }) }, { children: _jsx(Menu, {}) })), _jsx("div", __assign({ className: clsx("context-menu-area context-menu-toggle", { 'pointer-events-none': isPressed }) }, { children: _jsx(ContextMenu, {}) }))] })) }));
};
