var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { changePage, generateScenes, hideCanvasMenu, updateScenes } from '../../../../../../../../../../../redux/actions/canvas/canvas';
import store from "../../../../../../../../../../../redux/store";
import { clone } from "../../../../../../../../../../../utils/clone";
export var CreateEmptyPage = function () {
    var createPage = function () {
        var scenes = store.getState().canvas.scenes;
        var clonesScenes = clone(scenes);
        var _a = __read(generateScenes(1), 1), scene = _a[0];
        scene.canvasID = scenes.length;
        var updScenes = __spreadArray(__spreadArray([], __read(clonesScenes), false), [scene], false);
        updateScenes(updScenes);
        setTimeout(function () { changePage(scenes.length); }, 0);
        hideCanvasMenu();
    };
    return (_jsx(_Fragment, { children: _jsxs("li", __assign({ onClick: createPage }, { children: [_jsx("i", { className: "pi pi-plus create-empty-page" }), _jsx("span", { children: "Create new empty page" })] })) }));
};
