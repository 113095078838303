var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { Stage, Layer, Rect, Image, Transformer } from 'react-konva';
import { CanvasService, drawProccess, endDrawing, keyboardHandler, panningAndZoom, pasteHanlder, startDrawing } from "../../../../../../services/canvas/CanvasService";
import { getCurrentScene, cancelSelectedItem, setCurrentStage, cancelEditableItem } from "../../../../../../redux/actions/canvas/canvas";
import { CanvasImage } from "../elements/image/Image";
import { CanvasCurve } from "../elements/curve/Curve";
import { CanvasMicrophone } from "../elements/microphone/Microphone";
import { CanvasLine } from "../elements/line/Line";
import { CanvasNote } from "../elements/note/Note";
import store from "../../../../../../redux/store";
export var rectWidth = 1400;
export var rectHeight = 900;
export var CanvasArea = function () {
    var _a;
    var ref = useRef(null);
    var trRef = useRef();
    var _b = useSelector(function (state) { return state; }).canvas, instrument = _b.instrument, rectAttrs = _b.rectAttrs, activeSceneId = _b.activeSceneId, isPressed = _b.isPressed, scenes = _b.scenes, scale = _b.scale, cameraOffset = _b.cameraOffset, selectedItem = _b.selectedItem, exportMode = _b.exportMode;
    var _c = __read(useState(''), 2), classes = _c[0], setClasses = _c[1];
    var _d = __read(useState(null), 2), currentScene = _d[0], setCurrentScene = _d[1];
    var service = new CanvasService();
    // Change the classes of canvas by instruments toggle 
    useEffect(function () {
        // Вынести в сервис
        if (instrument === 'pencil')
            return setClasses('pencil-mode');
        if (instrument === 'line')
            return setClasses('pencil-mode');
        if (instrument === 'eraser')
            return setClasses('pencil-eraser');
        if (instrument === 'glove' && isPressed)
            return setClasses('glove-mode-active');
        if (instrument === 'glove')
            return setClasses('glove-mode');
        // if (instrument === 'note') return setClasses('note-mode')
        setClasses('');
    }, [instrument, isPressed]);
    // useEffect(() => {
    //   if (instrument === 'glove' && ref.current) {
    //     ref.current.on('touchstart', handleTouchStart)
    //     ref.current.on('touchmove', handleTouchMove)
    //   } else {
    //     if (ref.current) {
    //       ref.current.off('touchstart', handleTouchStart)
    //       ref.current.off('touchmove', handleTouchMove)
    //     }
    //   }
    //   return () => {
    //     if (ref.current) {
    //       ref.current.off('touchstart', handleTouchStart)
    //       ref.current.off('touchmove', handleTouchMove)
    //     }
    //   }
    // }, [instrument])
    // Canvas init and toggle
    useEffect(function () {
        if (ref.current) {
            setCurrentStage(ref.current);
            var scene = getCurrentScene();
            if (scene)
                service.initialize(scene);
        }
    }, [ref, activeSceneId]);
    // Canvas init and toggle
    useEffect(function () {
        if (selectedItem) {
            trRef.current.nodes([selectedItem.ref]);
            trRef.current.getLayer().batchDraw();
        }
        else {
            if (selectedItem) {
                trRef.current.nodes([]);
            }
        }
    }, [selectedItem]);
    useEffect(function () {
        var scene = getCurrentScene();
        setCurrentScene(scene ? scene : null);
    }, [activeSceneId, scenes]);
    useEffect(function () {
        if (ref.current) {
            ref.current.on('wheel', panningAndZoom);
            ref.current.on('pointerdown', startDrawing);
            ref.current.on('pointermove', drawProccess);
            ref.current.on('pointerup', endDrawing);
            // ref.current.on('touchstart', handleTouchStart)
            // ref.current.on('touchmove', handleTouchMove)
            document.addEventListener('pointerleave', endDrawing);
            document.addEventListener('keydown', keyboardHandler);
            document.addEventListener('paste', pasteHanlder);
        }
        return function () {
            if (ref.current) {
                ref.current.off('wheel', panningAndZoom);
                ref.current.off('pointerdown', startDrawing);
                ref.current.off('pointermove', drawProccess);
                ref.current.off('pointerup', endDrawing);
                // ref.current.off('touchstart', handleTouchStart)
                // ref.current.off('touchmove', handleTouchMove)
            }
            document.removeEventListener('pointerleave', endDrawing);
            document.removeEventListener('keydown', keyboardHandler);
            document.removeEventListener('paste', pasteHanlder);
        };
    }, [ref, activeSceneId]);
    // Очищаем selectedItem если он выбран но кликнули не по canvas
    useEffect(function () {
        if (selectedItem) {
            var handleClickOutside_1 = function (event) {
                var _a = store.getState().canvas, selected = _a.selectedItem, currentInstrument = _a.instrument;
                // Не скрывать выбранный элемент, если:
                // Нажимаем на image pin 
                if (event.target.className.includes('pinned-toggle'))
                    return false;
                if (event.target.nodeName !== 'CANVAS') {
                    if (selected.type === 'note' && currentInstrument === 'note')
                        return false;
                    cancelSelectedItem();
                    cancelEditableItem();
                }
            };
            document.addEventListener("click", handleClickOutside_1);
            return function () {
                document.removeEventListener("click", handleClickOutside_1);
            };
        }
    }, [selectedItem]);
    var height = window.screen.height;
    var width = window.screen.width;
    // const height = window.innerHeight
    // const width = window.innerWidth
    return (_jsx(_Fragment, { children: _jsxs(Stage, __assign({ ref: ref, className: clsx((_a = {
                    'flex flex-column app-canvas absolute': true
                },
                _a[classes] = classes,
                _a)), x: cameraOffset.x, y: cameraOffset.y, scaleX: scale, scaleY: scale, width: window.innerWidth, height: window.innerHeight, id: "canvas".concat(activeSceneId) }, { children: [_jsxs(Layer, { children: [!exportMode ? (_jsx(Rect, { x: -2000, y: -2000, width: 5500, height: 5500 })) : '', _jsx(Rect, __assign({ x: 0, y: 0 }, rectAttrs, { width: rectWidth, height: rectHeight })), (currentScene === null || currentScene === void 0 ? void 0 : currentScene.background) ? ((currentScene === null || currentScene === void 0 ? void 0 : currentScene.background.orientation) && (currentScene === null || currentScene === void 0 ? void 0 : currentScene.background.orientation) === 'portrait' ? (_jsx(_Fragment, { children: _jsx(Image, { x: 0, y: 0, width: (currentScene === null || currentScene === void 0 ? void 0 : currentScene.background.width) * (currentScene === null || currentScene === void 0 ? void 0 : currentScene.background.coef), height: (currentScene === null || currentScene === void 0 ? void 0 : currentScene.background.isLast) ? (currentScene === null || currentScene === void 0 ? void 0 : currentScene.background.height) * (currentScene === null || currentScene === void 0 ? void 0 : currentScene.background.coef) : rectHeight, image: currentScene === null || currentScene === void 0 ? void 0 : currentScene.background.image }) })) : (currentScene === null || currentScene === void 0 ? void 0 : currentScene.background.width) > (currentScene === null || currentScene === void 0 ? void 0 : currentScene.background.height) ?
                            (_jsx(_Fragment, { children: _jsx(Image, { x: 0, y: 0, width: rectWidth, height: rectHeight, image: currentScene === null || currentScene === void 0 ? void 0 : currentScene.background.image }) })) : (_jsx(_Fragment, { children: _jsx(Image, { x: 0, y: 0, width: currentScene === null || currentScene === void 0 ? void 0 : currentScene.background.width, height: rectHeight, image: currentScene === null || currentScene === void 0 ? void 0 : currentScene.background.image }) }))) : ''] }), _jsx(Layer, { children: currentScene && currentScene.images
                        .sort(function (a, b) { return a.time - b.time; })
                        .map(function (image, i) {
                        return (_jsx(CanvasImage, { image: image }, image.id + '_' + i));
                    }) }), _jsx(Layer, { children: currentScene && (_jsx(_Fragment, { children: __spreadArray(__spreadArray(__spreadArray([], __read(currentScene.curves), false), __read(currentScene.lines), false), __read(currentScene.notes), false).sort(function (a, b) { return a.time - b.time; })
                            .map(function (item, i) {
                            if (item.tool === 'curve')
                                return _jsx(CanvasCurve, { line: item }, item.id);
                            if (item.tool === 'note') {
                                return _jsx(CanvasNote, { note: item }, item.id);
                            }
                            return _jsx(CanvasLine, { line: item }, item.id);
                        }) })) }), _jsx(Layer, { children: selectedItem && (_jsx(Transformer, { ref: trRef, resizeEnabled: !['note'].includes(selectedItem.type), boundBoxFunc: function (oldBox, newBox) {
                            // limit resize
                            if (newBox.width < 5 || newBox.height < 5) {
                                return oldBox;
                            }
                            return newBox;
                        } })) }), _jsx(Layer, { children: currentScene && currentScene.voiceRecords
                        .map(function (record, i) {
                        return _jsx(CanvasMicrophone, { record: record }, record.id);
                    }) })] })) }));
};
