var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { locale } from "primereact/api";
import { appInitialization } from "./utils/initialization";
import Notifications from "./shared/components/particals/notifications/Notifications";
import { Pages } from "./shared/pages/Pages";
import './index.scss';
appInitialization();
var App = function () {
    // Set app language
    var lang = useSelector(function (state) { return state.languages; }).lang;
    locale(lang);
    return (_jsx("div", __assign({ className: "coloring-app" }, { children: _jsxs(_Fragment, { children: [_jsx(Notifications, {}), _jsx(Pages, {})] }) })));
};
export default App;
