var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { getCurrentScene, resaveImageItem } from "../../../../../../../../../../../redux/actions/canvas/canvas";
export var PinImage = function () {
    var _a = useSelector(function (state) { return state.canvas; }), selectedItem = _a.selectedItem, scenes = _a.scenes, activeSceneId = _a.activeSceneId;
    var pinImageToggle = function (event) {
        var scene = getCurrentScene();
        if (scene) {
            var image_1 = scene.images.find(function (image) { return image.id === selectedItem.id; });
            resaveImageItem(image_1.id, { pinned: !image_1.pinned });
        }
    };
    if (!selectedItem)
        return _jsx(_Fragment, {});
    var scene = scenes.find(function (scene) { return scene.canvasID === activeSceneId; });
    var image = scene === null || scene === void 0 ? void 0 : scene.images.find(function (image) { return image.id === selectedItem.id; });
    return image ?
        (_jsxs("div", __assign({ className: 'context-block' }, { children: [_jsx("label", { children: "Image settings" }), _jsxs("div", __assign({ className: "pinned-image flex gap-2 align-items-center" }, { children: [_jsx("div", __assign({ className: "cover pinned-toggle", onClick: function (event) { return pinImageToggle(event); } }, { children: image.pinned ? (_jsx("i", { className: "pi pi-check pinned-toggle font-bold" })) : '' })), _jsx("span", { children: "Pin image" })] }))] }))) : _jsx(_Fragment, {});
};
