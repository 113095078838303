import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { setInstrument } from "../../../../../../../redux/actions/canvas/canvas";
export var Gallery = function () {
    var instrument = useSelector(function (state) { return state.canvas; }).instrument;
    var instrumentToggle = function () { return setInstrument('gallery'); };
    return (_jsx(Button, { onClick: instrumentToggle, className: clsx({
            "pi pi-image remove-button-text": true,
            "active": instrument === 'gallery'
        }) }));
};
