var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { v4 as uuidv4 } from 'uuid';
import { ADD_NEW_SCENE, CHANGE_PAGE, HIDE_CANVAS_MENU, MAX_SCALE, MIN_SCALE, RESAVE_CANVAS_STORE, SET_CAMERA_ACTIVE_MODE, SET_CAMERA_OFFSET, SET_CAMERA_PAUSED_MODE, SET_CANVAS, SET_CANVAS_SCALE, SET_CURRENT_STAGE, SET_EDITABLE_ITEM, SET_ERASER_TRASH_CAN, SET_EXPORT_MODE, SET_HIDDEN_CONTEXT_MENU_MODE, SET_INITIAL_FINGER_DIST, SET_INSTRUMENT, SET_LINE_INSTRUMENT_ATTR, SET_MICROPHONES_LABEL_VISIBLE, SET_MICROPHONE_ACTIVE_MODE, SET_NOTE_ATTRIBUTES, SET_PENCIL_INSTRUMENT_ATTR, SET_PRESSING_MODE, SET_RECT_ATTR, SET_SELECTED_ITEM, SET_STARTING_COORDINATES, SHOW_CANVAS_MENU, UPDATE_SCENES } from "../../reducers/canvas";
import store from "../../store";
import { clone } from "../../../utils/clone";
export var setCanvas = function (data) {
    var canvas = {
        type: SET_CANVAS,
        payload: data
    };
    return store.dispatch(canvas);
};
export var getCurrentScene = function () {
    var _a = store.getState().canvas, activeSceneId = _a.activeSceneId, scenes = _a.scenes;
    var scene = scenes.find(function (scene) { return scene.canvasID === activeSceneId; });
    return clone(scene);
};
export var setCurrentStage = function (stage) {
    var action = {
        type: SET_CURRENT_STAGE,
        payload: stage
    };
    return store.dispatch(action);
};
export var returnPrevious = function () {
    var scene = getCurrentScene();
    if (scene) {
        var history_1 = scene.history;
        var last = history_1.pop();
        updateScene(last);
    }
};
export var addHistory = function () {
    var scene = getCurrentScene();
    if (scene) {
        scene.history = [];
        scene.history.push(clone(scene));
        updateScene(scene);
    }
};
export var setScale = function (scale) {
    return store.dispatch({
        type: SET_CANVAS_SCALE,
        payload: scale
    });
};
export var getCurrentStage = function () {
    var currentStage = store.getState().canvas.currentStage;
    return currentStage;
};
export var removeAllEmptyNotes = function () {
    var scene = getCurrentScene();
    if (scene) {
        scene.notes = scene.notes.filter(function (item) { return item.content.trim(); });
        updateScene(scene);
    }
};
export var removeRectBorder = function () {
    hideMicrophones();
    return store.dispatch({
        type: SET_RECT_ATTR,
        payload: clone({
            stroke: '#ccc',
            fill: '#fff'
        })
    });
};
export var addRectBorder = function () {
    showMicrophones();
    return store.dispatch({
        type: SET_RECT_ATTR,
        payload: clone({
            stroke: '#ccc',
            fill: '#fff',
            dash: [10, 10]
        })
    });
};
export var setStartingCoordinates = function (x, y) {
    var canvas = {
        type: SET_STARTING_COORDINATES,
        payload: { x: x, y: y }
    };
    return store.dispatch(canvas);
};
export var setCameraOffset = function (x, y) {
    localStorage.setItem('canvas-cameraOffset-x', x.toString());
    localStorage.setItem('canvas-cameraOffset-y', y.toString());
    var canvas = {
        type: SET_CAMERA_OFFSET,
        payload: { x: x, y: y }
    };
    return store.dispatch(canvas);
};
export var setPressingMode = function (mode) {
    var canvas = {
        type: SET_PRESSING_MODE,
        payload: mode
    };
    return store.dispatch(canvas);
};
export var updateScenes = function (scenes) {
    var dispatch = {
        type: UPDATE_SCENES,
        payload: clone(scenes)
    };
    return store.dispatch(dispatch);
};
export var updateScene = function (scene) {
    var scenes = store.getState().canvas.scenes;
    var clonedScenes = clone(scenes);
    updateScenes(clonedScenes.map(function (prevScene) {
        if (prevScene.canvasID === scene.canvasID)
            prevScene = scene;
        return prevScene;
    }));
};
export var setInstrument = function (instrument) {
    // setHiddenContextMenuMode()
    var canvas = {
        type: SET_INSTRUMENT,
        payload: instrument
    };
    return store.dispatch(canvas);
};
export var setSelectedItem = function (item) {
    var canvas = {
        type: SET_SELECTED_ITEM,
        payload: item
    };
    return store.dispatch(canvas);
};
export var cancelSelectedItem = function () {
    setSelectedItem(null);
};
export var setEditableItem = function (item) {
    var canvas = {
        type: SET_EDITABLE_ITEM,
        payload: item
    };
    return store.dispatch(canvas);
};
export var cancelEditableItem = function () {
    removeAllEmptyNotes();
    setEditableItem(null);
};
export var removeSelectedItem = function () {
    var selectedItem = store.getState().canvas.selectedItem;
    var scene = getCurrentScene();
    if (selectedItem && scene) {
        var id_1 = selectedItem.id;
        scene.curves = scene.curves.filter(function (item) { return item.id !== id_1; });
        scene.images = scene.images.filter(function (item) { return item.id !== id_1; });
        scene.notes = scene.notes.filter(function (item) { return item.id !== id_1; });
        setSelectedItem(null);
        updateScene(scene);
    }
};
export var replaceNoteById = function (id, updNote) {
    var scene = getCurrentScene();
    if (scene) {
        scene.notes = scene.notes.map(function (item) {
            if (item.id === id)
                return updNote;
            return item;
        });
        updateScene(scene);
    }
};
export var findNoteById = function (id) {
    var scene = getCurrentScene();
    if (scene) {
        var note = scene.notes.find(function (item) { return item.id === id; });
        return note ? clone(note) : null;
    }
    return null;
};
export var removeEmptyNotes = function () {
    var selectedItem = store.getState().canvas.selectedItem;
    var scene = getCurrentScene();
    if (scene) {
        scene.notes = scene.notes.filter(function (item) { return item.content.trim(); });
        updateScene(scene);
    }
};
export var setCurves = function (curves) {
    var scene = getCurrentScene();
    if (scene) {
        scene.curves = curves;
        updateScene(scene);
    }
};
export var setLines = function (lines) {
    var scene = getCurrentScene();
    if (scene) {
        scene.lines = lines;
        updateScene(scene);
    }
};
export var setNotes = function (notes) {
    var scene = getCurrentScene();
    if (scene) {
        scene.notes = notes;
        updateScene(scene);
    }
};
/* Scaling start */
export var scaleIncrease = function () {
    var scale = store.getState().canvas.scale;
    var value = scale < MAX_SCALE ? scale + 0.1 : scale;
    value = Number(value.toFixed(2));
    localStorage.setItem('canvas-scale', value.toString());
    var canvas = {
        type: SET_CANVAS_SCALE,
        payload: value
    };
    return store.dispatch(canvas);
};
export var scaleDecrease = function () {
    var scale = store.getState().canvas.scale;
    var value = scale > MIN_SCALE ? scale - 0.1 : scale;
    value = Number(value.toFixed(2));
    localStorage.setItem('canvas-scale', value.toString());
    var canvas = {
        type: SET_CANVAS_SCALE,
        payload: value
    };
    return store.dispatch(canvas);
};
export var scaleWheelIncrease = function (x, y) {
    var _a = store.getState().canvas, scale = _a.scale, cameraOffset = _a.cameraOffset;
    var newScale = scale < MAX_SCALE ? scale + 0.04 : scale;
    newScale = Number(newScale.toFixed(2));
    // Вычисление новых координат камеры
    var newX = x + (cameraOffset.x - x) * (newScale / scale);
    var newY = y + (cameraOffset.y - y) * (newScale / scale);
    // Запись нового масштаба в localStorage
    localStorage.setItem('canvas-scale', newScale.toString());
    // Диспатчим новый масштаб
    var canvas = {
        type: SET_CANVAS_SCALE,
        payload: newScale
    };
    store.dispatch(canvas);
    // Установка нового положения камеры
    setCameraOffset(newX, newY);
};
export var scaleWheelDecrease = function (x, y) {
    var _a = store.getState().canvas, scale = _a.scale, cameraOffset = _a.cameraOffset;
    var newScale = scale > MIN_SCALE ? scale - 0.04 : scale;
    newScale = Number(newScale.toFixed(2));
    // Вычисление новых координат камеры
    var newX = x + (cameraOffset.x - x) * (newScale / scale);
    var newY = y + (cameraOffset.y - y) * (newScale / scale);
    // Запись нового масштаба в localStorage
    localStorage.setItem('canvas-scale', newScale.toString());
    // Диспатчим новый масштаб
    var canvas = {
        type: SET_CANVAS_SCALE,
        payload: newScale
    };
    store.dispatch(canvas);
    // Установка нового положения камеры
    setCameraOffset(newX, newY);
};
export var setDefaultScale = function () {
    setCameraOffset(0, 0);
    localStorage.setItem('canvas-scale', '1');
    localStorage.setItem('canvas-cameraOffset-x', '0');
    localStorage.setItem('canvas-cameraOffset-y', '0');
    var canvas = {
        type: SET_CANVAS_SCALE,
        payload: 1
    };
    return store.dispatch(canvas);
};
/* Scaling end */
export var resaveCanvasStore = function (canvas) {
    var storeAction = {
        type: RESAVE_CANVAS_STORE,
        payload: clone(canvas)
    };
    return store.dispatch(storeAction);
};
export var changePage = function (id) {
    var storeAction = {
        type: CHANGE_PAGE,
        payload: id
    };
    return store.dispatch(storeAction);
};
export var addImage = function (image) {
    var scene = getCurrentScene();
    var id = uuidv4();
    if (scene) {
        scene.images.push({
            id: id,
            image: image,
            rotation: 0,
            height: image.height,
            width: image.width,
            // height: 200,
            // width: 200,
            x: 50,
            y: 50,
            scaleX: 1,
            scaleY: 1,
            time: new Date(),
            pinned: false
        });
        updateScene(scene);
    }
};
export var resaveImage = function (id, x, y, rotation, scaleX, scaleY, height, width) {
    var scene = getCurrentScene();
    if (scene) {
        var updImage = scene.images.find(function (image) { return image.id === id; });
        if (updImage) {
            var copy_1 = clone(updImage);
            if (x && y) {
                copy_1.x = x;
                copy_1.y = y;
            }
            if (rotation !== undefined) {
                copy_1.rotation = rotation;
                copy_1.scaleX = scaleX;
                copy_1.scaleY = scaleY;
                copy_1.height = height;
                copy_1.width = width;
            }
            copy_1.time = new Date();
            scene.images = scene.images.filter(function (image) { return image.id !== copy_1.id; });
            scene.images.push(copy_1);
            updateScene(scene);
        }
    }
};
export var resaveImageItem = function (id, updFiles) {
    var scene = getCurrentScene();
    if (scene) {
        var updImage = scene.images.find(function (image) { return image.id === id; });
        if (updImage) {
            var copy_2 = Object.assign(clone(updImage), updFiles);
            copy_2.time = new Date();
            scene.images = scene.images.filter(function (image) { return image.id !== copy_2.id; });
            scene.images.push(copy_2);
            updateScene(scene);
        }
    }
};
export var resaveNote = function (id, x, y, rotation, height, width) {
    var scene = getCurrentScene();
    if (scene) {
        var updNote = scene.notes.find(function (note) { return note.id === id; });
        if (updNote) {
            var copy_3 = clone(updNote);
            if (x && y) {
                copy_3.points = [x, y];
            }
            if (rotation !== undefined) {
                copy_3.rotation = rotation;
                copy_3.height = height;
                copy_3.width = width;
            }
            copy_3.time = new Date();
            scene.notes = scene.notes.filter(function (item) { return item.id !== copy_3.id; });
            scene.notes.push(copy_3);
            updateScene(scene);
        }
    }
};
export var resaveNoteContent = function (id, content) {
    var scene = getCurrentScene();
    if (scene) {
        var updNote = scene.notes.find(function (note) { return note.id === id; });
        if (updNote) {
            var copy_4 = clone(updNote);
            copy_4.content = content;
            // copy.time = new Date()
            scene.notes = scene.notes.filter(function (item) { return item.id !== copy_4.id; });
            scene.notes.push(copy_4);
            updateScene(scene);
        }
    }
};
export var resaveVoiceRecorder = function (id, x, y) {
    var scene = getCurrentScene();
    if (scene) {
        var updVoiceRecorder = scene.voiceRecords.find(function (voiceRecord) { return voiceRecord.id === id; });
        if (updVoiceRecorder) {
            var copy_5 = clone(updVoiceRecorder);
            if (x && y) {
                copy_5.x = x;
                copy_5.y = y;
            }
            copy_5.date = new Date();
            scene.voiceRecords = scene.voiceRecords.filter(function (voiceRecord) { return voiceRecord.id !== copy_5.id; });
            scene.voiceRecords.push(copy_5);
            updateScene(scene);
        }
    }
};
export var generateScenes = function (num) {
    var generateArray = function (n) { return Array.from({ length: n }, function (_, i) { return i; }); };
    var ids = generateArray(num);
    var deafultScenes = [];
    ids.forEach(function (id) {
        deafultScenes.push(clone({
            canvas: null,
            ctx: null,
            history: [],
            stage: null,
            canvasID: id,
            background: null,
            voiceRecords: [],
            curves: [],
            images: [],
            lines: [],
            notes: []
        }));
    });
    return deafultScenes;
};
export var hideCanvasMenu = function () {
    var action = {
        type: HIDE_CANVAS_MENU, payload: 1
    };
    return store.dispatch(action);
};
export var showCanvasMenu = function () {
    var action = {
        type: SHOW_CANVAS_MENU, payload: 1
    };
    return store.dispatch(action);
};
export var addNewScene = function () {
    var scenes = store.getState().canvas.scenes;
    var lastScene = scenes[scenes.length - 1];
    var _a = __read(generateScenes(1), 1), scene = _a[0];
    scene.canvasID = lastScene.canvasID + 1;
    changePage(lastScene.canvasID + 1);
    var action = {
        type: ADD_NEW_SCENE, payload: scene
    };
    store.dispatch(action);
    return lastScene.canvasID + 1;
};
export var removeCurrentScene = function () {
    var scenes = store.getState().canvas.scenes;
    var clonedScenes = clone(scenes);
    var scene = getCurrentScene();
    if (scene) {
        var id_2 = scene.canvasID;
        clonedScenes = clonedScenes
            .filter(function (scene) { return scene.canvasID !== id_2; })
            .map(function (scene, i) {
            scene.canvasID = i;
            return scene;
        });
        changePage(id_2 === 0 ? 0 : id_2 - 1);
        updateScenes(clonedScenes);
    }
};
export var cleanCurrentScene = function () {
    var scene = getCurrentScene();
    if (scene) {
        scene.curves = [];
        scene.images = [];
        scene.history = [];
        scene.lines = [];
        scene.notes = [];
        updateScene(scene);
    }
};
export var setPencilColor = function (color) {
    var pencilAttrs = store.getState().canvas.pencilAttrs;
    var cloned = clone(pencilAttrs);
    cloned.stroke = color;
    var action = {
        type: SET_PENCIL_INSTRUMENT_ATTR, payload: cloned
    };
    return store.dispatch(action);
};
export var enablePencilDashedMode = function () {
    var pencilAttrs = store.getState().canvas.pencilAttrs;
    var cloned = clone(pencilAttrs);
    cloned.dash = [25, 25];
    var action = {
        type: SET_PENCIL_INSTRUMENT_ATTR, payload: cloned
    };
    return store.dispatch(action);
};
export var disablePencilDashedMode = function () {
    var pencilAttrs = store.getState().canvas.pencilAttrs;
    var cloned = clone(pencilAttrs);
    delete cloned.dash;
    var action = {
        type: SET_PENCIL_INSTRUMENT_ATTR, payload: cloned
    };
    return store.dispatch(action);
};
export var setPencilStrokeWidth = function (width) {
    var pencilAttrs = store.getState().canvas.pencilAttrs;
    var cloned = clone(pencilAttrs);
    cloned.strokeWidth = width;
    var action = {
        type: SET_PENCIL_INSTRUMENT_ATTR, payload: cloned
    };
    return store.dispatch(action);
};
export var setLineColor = function (color) {
    var lineAttrs = store.getState().canvas.lineAttrs;
    var cloned = clone(lineAttrs);
    cloned.stroke = color;
    var action = {
        type: SET_LINE_INSTRUMENT_ATTR, payload: cloned
    };
    return store.dispatch(action);
};
export var enableLineDashedMode = function () {
    var lineAttrs = store.getState().canvas.lineAttrs;
    var cloned = clone(lineAttrs);
    cloned.dash = [25, 25];
    var action = {
        type: SET_LINE_INSTRUMENT_ATTR, payload: cloned
    };
    return store.dispatch(action);
};
export var disableLineDashedMode = function () {
    var lineAttrs = store.getState().canvas.lineAttrs;
    var cloned = clone(lineAttrs);
    delete cloned.dash;
    var action = {
        type: SET_LINE_INSTRUMENT_ATTR, payload: cloned
    };
    return store.dispatch(action);
};
export var setLineStrokeWidth = function (width) {
    var lineAttrs = store.getState().canvas.lineAttrs;
    var cloned = clone(lineAttrs);
    cloned.strokeWidth = width;
    var action = {
        type: SET_LINE_INSTRUMENT_ATTR, payload: cloned
    };
    return store.dispatch(action);
};
export var changeNoteAttributes = function (attr) {
    var action = {
        type: SET_NOTE_ATTRIBUTES, payload: attr
    };
    return store.dispatch(action);
};
export var setEraserTrash = function (ids) {
    var action = {
        type: SET_ERASER_TRASH_CAN, payload: ids
    };
    return store.dispatch(action);
};
export var setExportMode = function (mode) {
    var action = {
        type: SET_EXPORT_MODE, payload: mode
    };
    return store.dispatch(action);
};
export var setHiddenContextMenuMode = function () {
    return store.dispatch({
        type: SET_HIDDEN_CONTEXT_MENU_MODE, payload: true
    });
};
export var setUnhiddenContextMenuMode = function () {
    return store.dispatch({
        type: SET_HIDDEN_CONTEXT_MENU_MODE, payload: false
    });
};
export var enableMicrophone = function () {
    return store.dispatch({
        type: SET_MICROPHONE_ACTIVE_MODE, payload: true
    });
};
export var disableMicrophone = function () {
    return store.dispatch({
        type: SET_MICROPHONE_ACTIVE_MODE, payload: false
    });
};
export var stopCameraRecord = function () {
    return store.dispatch({
        type: SET_CAMERA_PAUSED_MODE, payload: true
    });
};
export var continueCameraRecord = function () {
    return store.dispatch({
        type: SET_CAMERA_PAUSED_MODE, payload: false
    });
};
export var startCameraRecord = function () {
    return store.dispatch({
        type: SET_CAMERA_ACTIVE_MODE, payload: true
    });
};
export var finishCameraRecord = function () {
    continueCameraRecord();
    return store.dispatch({
        type: SET_CAMERA_ACTIVE_MODE, payload: false
    });
};
export var hideMicrophones = function () {
    return store.dispatch({
        type: SET_MICROPHONES_LABEL_VISIBLE, payload: false
    });
};
export var showMicrophones = function () {
    return store.dispatch({
        type: SET_MICROPHONES_LABEL_VISIBLE, payload: true
    });
};
export var setInitialFinderDist = function (dist) {
    return store.dispatch({
        type: SET_INITIAL_FINGER_DIST, payload: dist
    });
};
