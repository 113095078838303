var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { setInstrument } from "../../../../../../../redux/actions/canvas/canvas";
import { images } from "../../../../../../../assets/images/images";
export var Cursor = function () {
    var instrument = useSelector(function (state) { return state.canvas; }).instrument;
    var instrumentToggle = function () { return setInstrument('cursor'); };
    return (_jsx(Button, __assign({ onClick: instrumentToggle, className: clsx({
            "remove-button-text": true,
            "active": instrument === 'cursor'
        }) }, { children: _jsx("img", { src: images.cursor, alt: "instrument" }) })));
};
