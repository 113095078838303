var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Circle, Group, Text } from "react-konva";
import { addHistory, resaveVoiceRecorder } from "../../../../../../../redux/actions/canvas/canvas";
import { useSelector } from "react-redux";
export var CanvasMicrophone = function (_a) {
    var record = _a.record;
    var _b = useSelector(function (state) { return state; }).canvas, instrument = _b.instrument, microphonesLabelVisible = _b.microphonesLabelVisible;
    var onVoiceRecordDragEnd = function (event) {
        addHistory();
        var x = event.target.x();
        var y = event.target.y();
        resaveVoiceRecorder(record.id, x, y);
    };
    return (_jsxs(Group, __assign({ draggable: ['cursor', 'microphone'].includes(instrument), onDragEnd: function (event) { return onVoiceRecordDragEnd(event); }, opacity: microphonesLabelVisible ? 1 : 0, x: record.x, y: record.y }, { children: [_jsx(Circle, { radius: 15, fill: '#75C2F6' }), _jsx(Text, { y: -7, x: (record.idCounter).toString() === '1' ? -3 : -5, text: (record.idCounter).toString(), fill: "#000", fontSize: 16, fontStyle: "bold", fontFamily: "Regular", wrap: "char" })] }), record.id));
};
